export default [{
        path: "/",
        name: "hometest",
        component: () => import("@/views/HomeNew.vue")
    },{
        path: "/login",
        name: "newlogin",
        component: () => import("@/views/NewLogin.vue")
    },{
        path: "/documentos-descargables",
        name: "documentos-descargables",
        component: () => import("@/views/DocumentosDescargables.vue")
    },{
        path: "/como-funciona-solicitar",
        name: "solicitante", //old Action
        component: () => import("@/views/HowItWorkSolicit.vue"),
    },{
        path: "/solicitante",
        redirect: "/como-funciona-solicitar"
    },{
        path: "/contacto",
        name: "contact",
        component: () => import("@/views/Contact.vue")
    },{
        path: "/quienes-somos",
        name: "about",
        component: () => import("@/views/aboutUs/AboutUs.vue")
    },{
        path: "/como-funciona-invertir",
        name: "comofuncionainvertir",
        component: () => import("@/views/HowItWorksNew.vue")
    },{
        path: "/como-funciona",
        redirect: "como-funciona-invertir"
    },{
        path: "/solicitar-credito",
        name: "request-credit",
        component: () => import("@/views/NewCredit.vue")
    },{
        path: "/garantia-riesgos",
        name: "warrantyrisk",
        component: () => import("@/views/Warranty.vue")
    },{
        path: "/preguntas-frecuentes",
        name: "faq",
        component: () => import("@/views/Faq.vue")
    },{
        path: "/ingresar",
        name: "login",
        component: () => import("@/views/Login.vue")
    },{
        path: "/registrar",
        name: "register",
        component: () => import("@/views/Register.vue")
    },{
        path: "/registro",
        name: "register-requests",
        component: () => import("@/views/RegisterRequest.vue")
    },{
        path: "/recuperar",
        name: "recuperar",
        component: () => import("@/views/Recover.vue")
    },{
        path: "/recuperar/:token",
        name: "recuperar contraseña",
        component: () => import("@/views/NewPassword.vue"),
        props: true
      },{
        path: "/prensa",
        name: "press",
        component: () => import("@/views/Press.vue")
      },{
        path: "/simulacion/proyecto-:projectId",
        name: "simulation",
        component: () => import("@/views/Simulation.vue"),
        props: true
      },{
        path: "/inversion-realizada",
        name: "investment-completed",
        component: () => import("@/views/InvestmentCompleted.vue")
      },{
        path: "/tarjetaprepago",
        name: "tarjeta-prepago",
        component: () => import("@/views/landingVisa/Landing.vue")
      },{
        path: "/tarjetaprepago/contacto",
        name: "tarjeta-prepago-contacto",
        component: () => import("@/views/landingVisa/Contacto.vue")
      },   
          
]