




























































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  TitleTemplateDashboard,
  SelectedCountry,
  RedCapital
} from "@/configuration";
import { mapState } from "vuex";
import API from "@/api";
// @ts-ignore
import ChartJS from "chart.js";
// @ts-ignore
import { Tabs, TabItem } from 'vue-material-tabs';

@Component({
  metaInfo: {
    title: "Estadísticas",
    titleTemplate: TitleTemplateDashboard,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang
    }
  },
  computed: {
    ...mapState(["user", "setOpenMenuPanel"]),
    setOpenMenuPanel(){
      return this.$store.state.openMenuPanel;
    }
  },
  data() {
    return {
      tabs: 0,
      isReady: false,
      montos: {},
      context: null,
      chart: null,
      signoPeso:1,
      radio: 'sol',
    };
  },
  beforeMount() {
    // @ts-ignore
    this.getAll();
  },
  methods: {
    async getAll() {
      this.$data.montos.montoTotalInvertido = await API.dashboard.montoTotalInvertido(this.$data.signoPeso);
      this.$data.montos.montoTotalRecibidoProyectoCerrado = await API.dashboard.montoTotalRecibidoProyectoCerrado(this.$data.signoPeso);
      this.$data.montos.montoTotalInvertidoPendienteDePago = await API.dashboard.montoTotalInvertidoPendienteDePago(this.$data.signoPeso);
      this.$data.montos.rentabilidadProyectosPagadosCerrados = await API.dashboard.rentabilidadProyectosPagadosCerrados(this.$data.signoPeso);
      this.$data.montos.rentabilidadProyectosMoraMayor180DiasImpagas = await API.dashboard.rentabilidadProyectosMoraMayor180DiasImpagas(this.$data.signoPeso);
      this.$data.montos.totalMontosInvertidoProyectosActivos = await API.dashboard.totalMontosInvertidoProyectosActivos(this.$data.signoPeso);
      this.$data.isReady = true;
    }
  },
  watch:{
    tabs(a){
      if(a=='tab-1'){
          this.$data.signoPeso = 1;
      }
      else{
         this.$data.signoPeso = 2;
      }
      // @ts-ignore
      this.getAll();
    }
    
  },
  components: {
    Tabs,
    TabItem,
  },
})
export default class Stats extends Vue {}
