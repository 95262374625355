var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project-status"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('div',_vm._g({style:({
            border: '1px solid grey',
            width: '20px',
            height: '20px',
            backgroundColor: _vm.color(_vm.projectStatus),
            borderRadius: '25px'
          })},on))])]}}])},[_c('div',[_c('span',[_vm._v("\n        "+_vm._s(_vm.projectStatus)+"\n      ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }