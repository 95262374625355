
















































import { Component, Vue } from "vue-property-decorator";
import {
  TitleTemplateDashboard,
  SelectedCountry,
  RedCapital,
} from "@/configuration";
import DniPerson from "@/components/DniField.vue";
import { mapState } from "vuex";
// @ts-ignore
import PerfildeUsuario from "@/views/factoring/misdatos/NewPerfildeUsuario.vue";
import Cuentasbancarias from "@/views/factoring/misdatos/NewCuentasbancarias.vue"
// import Notification  from "@/views/factoring/misdatos/NewNotificaciones.vue"
import Password  from "@/views/factoring/misdatos/MyPassword.vue"
import AlertaAccion from "@/components/alertas/AlertaAccion.vue"

// @ts-ignore
import { Tabs, TabItem } from 'vue-material-tabs';
import ShowLoading from "@/components/panel/ShowLoading.vue";

@Component({
  metaInfo: {
    title: "Mis datos",
    titleTemplate: TitleTemplateDashboard,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    },
  },
  computed: {
    ...mapState(["user","siiConfigurations","setOpenMenuPanel", "cuentas_bancarias", "businesSelected"]),
    uploadDisabled() {
      return this.$data.files.length === 0;
    },
    uploadDisabled2() {
      return this.$data.files.length === 0;
    },
    setOpenMenuPanel(){
      return this.$store.state.openMenuPanel;
    },
  },
  data() {
    return {
      pasostab :0,
      steeps: 0,
      regions:[],
      communes:[],
      cambio: false,
      cambioFiltro: false,
      active: null,
      staticBusiness: "",
      banks: null,
      accountTypes: null,
      cuentas: false,
      agregar_cuenta: false,
      editar_cuenta: false,
      

    };
  },
  async beforeMount() {
    if(this.$route.params.step != null){
      this.$data.steeps = this.$route.params.step;
    } 
  
  },
  watch: {},
  methods: {

  },
  components: {
    DniPerson,
    PerfildeUsuario,
    Cuentasbancarias,
    // Notification,
    Tabs, TabItem,
    ShowLoading,
    Password,
    AlertaAccion
  },
})
export default class Myinfo extends Vue {}
