



















import { Component, Prop, Vue } from "vue-property-decorator";
import API from "@/api";
import { EventBus } from "@/main";

@Component({
  data() {
    return {
      snackbar: false,
      color: null,
      text: null
    };
  },
  beforeMount() {
    EventBus.$on("snack-success", (text: any) => {
      this.$data.snackbar = false;
      this.$data.snackbar = true;
      this.$data.color = "success";
      this.$data.text = text;
    });
    EventBus.$on("snack-error", (text: any) => {
      this.$data.snackbar = false;
      this.$data.snackbar = true;
      this.$data.color = "error";
      this.$data.text = text;
    });
    EventBus.$on("snack-warning", (text: any) => {
      this.$data.snackbar = false;
      this.$data.snackbar = true;
      this.$data.color = "warning";
      this.$data.text = text;
    });
  },
  beforeDestroy() {
    EventBus.$off(["snack-error", "snack-success", "snack-warning"]);
  }
})
export default class SnackError extends Vue {}
