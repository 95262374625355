


















import { Component, Prop, Vue } from "vue-property-decorator";
import { Configuration } from "@/configuration";

@Component({
  data() {
    return {
      blocked: false,
      textState: "",
      colorState: "primary"
      // colorSuccess: 'success',
      // colorError: 'error',
    };
  },
  created() {
    this.$data.textState = this.$props.text;
    this.$data.colorState = this.$props.color;
  },
  methods: {
    emitEvent() {
      if (!this.$data.blocked) {
        this.$emit("click");
      }
    }
  },
  watch: {
    "value.isOk"() {
      if (this.$props.value.isOk != null) {
        this.$data.blocked = true;
        this.$props.value.loading = false;
        this.$data.colorState = this.$props.value.isOk
          ? this.$props.colorSuccess
          : this.$props.colorError;
        this.$data.textState = this.$props.value.isOk
          ? this.$props.value.successText
          : this.$props.value.errorText;

        setTimeout(() => {
          this.$data.blocked = false;

          this.$data.textState = this.$props.text;
          this.$data.colorState = this.$props.color;

          this.$props.value.isOk = null;

          this.$emit("input", this.$props.value);
        }, this.$props.delay);
      }
    }
  }
})
export default class ReusableButton extends Vue {
  @Prop() value: any;
  @Prop() color!: string;
  @Prop({ default: "success" }) colorSuccess!: string;
  @Prop({ default: "error" }) colorError!: string;
  @Prop() text!: string;
  @Prop() delay!: number;
  @Prop({ default: false }) disabled!: boolean;
}
