


















































































































































































import DniField from "@/components/DniField.vue";
import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import ReusableButton from "@/components/ReusableButton.vue";
import API from "@/api";
import {
  SelectedCountry,
  RedCapital,
  Countries,
  TitleTemplateDashboard
} from "@/configuration";
import { EventBus } from "@/main";

@Component({
  metaInfo: {
    title: "Datos personales",
    titleTemplate: TitleTemplateDashboard,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang
    }
  },
  computed: {
    ...mapState(["user" ,"setOpenMenuPanel"]),
    setOpenMenuPanel(){
      return this.$store.state.openMenuPanel;
    }
  },
  data() {
    return {
      SelectedCountry,
      RedCapital,
      canShow: false,
      btn: {
        clickable: false,
        loading: false,
        isOk: null,
        successText: "Guardado correctamente",
        errorText: "Error interno"
      },
      deparment_id: null,
      province_id: null,
      userData: null,
      deparments: null,
      formatted: null,
      provinces: [],
      districts: [],
      rules: {
        notEmpty: [(v: string) => !!v || "Este campo es requerido"],
        email: [
          (v: string) =>
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            "El mail debe ser válido"
        ]
      },
      staticCountries: Countries,
      countries: null,
      civilStatus: [
        {
          id: "soltero",
          text: "Soltero(a)"
        },
        {
          id: "casado",
          text: "Casado(a)"
        },
        {
          id: "divorciado",
          text: "Divorciado(a)"
        },
        {
          id: "viudo",
          text: "Viudo(a)"
        }
      ],
      genders: [
        {
          id: "masculino",
          text: "Masculino"
        },
        {
          id: "femenino",
          text: "Femenino"
        }
      ]
    };
  },
  watch: {
    userData: {
      handler(val) {
        if (this.$data.canShow) {
          // @ts-ignore
          if (this.$refs.form.validate()) {
            // @ts-ignore
            this.$data.btn.clickable = this.hasChange();
          }
        }
      },
      deep: true
    },
    "userData.fec_nac":{handler(val){
      //@ts-ignore
      this.$data.userData.fec_nac_format=this.formatDate(val);
    }, deep: true},
  },
async beforeMount() {
 //   this.$data.deparment_id =1;
  //  this.$data.province_id =24;
API.getPersonalData(this.$store.state.user.id)
            .then(user => {
              //@ts-ignore
              this.$data.userData.distrito_id = user.distrito_id
              //@ts-ignore
              API.getDistrito_id(user.distrito_id)
              .then((res =>{
                //@ts-ignore
                // // console.log(res)
                //@ts-ignore
                this.$data.deparment_id = res.data.selected.departamento_id
                //@ts-ignore
               // // console.log(res.data.selected.id)
                //@ts-ignore
                this.$data.province_id = res.data.selected.id
                 API.getDistricts(
                   //@ts-ignore
                    res.data.selected.id
                  )
                  .then((res)=>{
                    this.$data.districts = res
                    // console.log(res)
                  })
                API.getProvinces(
                  //@ts-ignore
                    res.data.selected.departamento_id
                  ).then((res)=>{


                    this.$data.provinces = res
                   // this.$data.province_id
                    // console.log(res)
                  })
              }))
              .catch((error)=>{
                // console.log(error)
              })
             // this.$store.commit("setUser", user);
            })
            .finally(() => {
              // @ts-ignore
            //  this.resetUserData();
             // this.$data.btn.isOk = true;
            });
    // @ts-ignore
    this.resetUserData();
    this.$data.formatted = this.$store.state.user.rut;
    this.$data.canShow = true;
    EventBus.$emit('mostrarDatosPerfil', this.$data.canShow)
    // const format = this.$store.state.user.rut.split('') 
    // if (format.length == 10) {
    //   this.$data.formatted = `${format[0]}${format[1]}.${format[2]}${format[3]}${format[4]}.${format[5]}${format[6]}${format[7]}${format[8]}${format[9]}`
    // }else{
    //   this.$data.formatted = `${format[0]}.${format[1]}${format[2]}${format[3]}.${format[4]}${format[5]}${format[6]}${format[7]}${format[8]}`
    // }
    
      

  },
  methods: {
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}-${month}-${year}`
    },
    async resetUserData() {
      let temp = {};
      Object.assign(temp, this.$store.state.user);
      this.$data.userData = temp;

      // this.$data.deparments = await API.getdeparments(this.$data.userData.pais_id);

      // @ts-ignore
      this.updateDeparments();
      // @ts-ignore
      // this.updateDeparments();

      // @ts-ignore
      this.$data.btn.clickable = this.hasChange();
    },
    async updateDeparments() { //updateDeparments
      this.$data.deparments = await API.getDeparments();
      if (this.$data.deparment_id == null) {
        this.$data.deparment_id = this.$data.deparments[0].deparment_id;
      }

      // @ts-ignore
      this.updateProvinces();
    },
    async updateProvinces() { //updateProvinces
      this.$data.provinces = await API.getProvinces(
        this.$data.deparment_id
      );
      if (this.$data.provinces.length != 0) {
        if (this.$data.province_id == null) {
          this.$data.province_id = this.$data.provinces[0].province_id;
        }
      }
      this.$data.canShow = true;
      // @ts-ignore
      this.updateDistricts();
    },
    async updateDistricts() { //updateDistricts
      this.$data.districts = await API.getDistricts(
        this.$data.province_id
      );
      if (this.$data.districts.length != 0) {
        if (this.$data.userData.distrito_id == null) {
          this.$data.userData.distrito_id = this.$data.districts[0].district_id;
        }
      }
      this.$data.canShow = true;
      // @ts-ignore
      // this.updateProvinces();
    },
    hasChange() {
      let oldData = Object.values(this.$store.state.user);
      let newData = Object.values(this.$data.userData);

      let hasSomethingChange = false;

      for (let i in oldData) {
        if (oldData[i] != newData[i]) {
          if (newData[i] == "") {
            continue;
          }
          hasSomethingChange = true;
        }
      }

      return hasSomethingChange;
    },
    async saveChanges() {
      
      this.$data.btn.loading = true;
      // @ts-ignore
      if (this.$data.btn.clickable && this.$refs.form.validate()) {
        // console.log(this.$data.userData)
        API.updatePersonalData(this.$data.userData).then(data => {
          API.getPersonalData(this.$store.state.user.id)
            .then(user => {
              this.$store.commit("setUser", user);
            })
            .finally(() => {
              // @ts-ignore
              this.resetUserData();
              this.$data.btn.isOk = true;
            });
        });
      }
    }
  },
  components: {
    ReusableButton,
    DniField
  }
})
export default class PersonalData extends Vue {}
