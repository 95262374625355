














































































































































import { Component, Vue } from "vue-property-decorator";
import ReusableButton from "@/components/ReusableButton.vue";
import API from "@/api";
import { EventBus } from "@/main";

@Component({
    data(){
        return{
            passwords: {
                old: "",
                new: "",
                confirm: "",
            },
            show: {
                old: false,
                new: false,
                confirm: false,
			},
            rules:{
                notEmpty: [(v: any) => !!v || "Este campo es requerido"],
                password: [],
            },
            btn: {
                clickable: false,
                loading: false,
                isOk: null,
                // successText: "Contraseña actualizada",
                // errorText: "Error al actualizar la contraseña",
            },
            errorPass: false,
            dialog2:false,
            dialog: false,
        }
    },
    components:{
        ReusableButton,
    },
    watch:{
        passwords: {
                handler(val) {
                    this.$data.errorPass = false
                    // @ts-ignore
                    this.$data.btn.clickable = this.validatePassword(val.new) && (val.new == val.confirm) && val.old !== ''
                },
                deep: true,
        },
    },
    beforeMount() {
        API.getPasswordRules().then((response) => {
            const res = response.data;
            const rules = JSON.parse(res.data);
            Object.values(rules).map((e) => {
            //@ts-ignore
            let rule = {'text': Object.values(e)[0].text, 'regex':Object.values(e)[0].rule}
            this.$data.rules.password = [...this.$data.rules.password, rule];
        });
        }).catch(() =>{
            this.$data.rules.password = [];
        })
    },
    methods:{
        changePassword() {
            this.$data.dialog = true;
            // @ts-ignore
            this.$data.btn.loading = true;
            API.changePassword(
                this.$data.passwords.old,
                this.$data.passwords.new,
                this.$data.passwords.confirm
            )
            .then((res) => {
                this.$data.dialog2 = true;
                this.$data.dialog = false;
                this.$data.btn.isOk = true;
                // EventBus.$emit(
                //     "snack-success",
                //     "Contraseña actualizada correctamente"
                // );
                this.$data.passwords.old = null;
                this.$data.passwords.new = null;
                this.$data.passwords.confirm = null;
                // setTimeout(() => {
                //     this.$data.dialog2 = false;
                // },2000)
            })
            .catch((error) => {
                this.$data.btn.isOk = false;
                this.$data.dialog = false;
                this.$data.errorPass = true;
                // EventBus.$emit("snack-error", "Error al actualizar contraseña");
            });
            
		},
        verificarRegex(regex:string, password:string):boolean{
            var pattern = new RegExp(regex);
            return pattern.test(password);
        },
        validatePassword(password) {
            let contador = 0;
            let isValid = false;
            if(this.$data.rules.password.length > 0){
                //@ts-ignore
                this.$data.rules.password.forEach((rule,i)=>{
                //@ts-ignore
                if(this.verificarRegex(rule.regex, password)){
                        contador++;
                        if(contador == this.$data.rules.password.length){
                            isValid = true;
                        }
                        else{
                            isValid = false;
                        }
                    }
                })
            }
            else {
                isValid = true;
            }
                return isValid;
        },
    }
})
export default class MyPassword extends Vue{}
