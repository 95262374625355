<template> <!-- #Pendiente eliminar antiguas NavBar y NewNavbarFactoring -->
	<div class="dashboard-navbar">
		<!-- Menu Btn -->
		<v-layout wrap row align-start justify-start style="position:fixed;z-index:1;margin: 10px">
			<v-flex xs12 @click="setOpenMenu()">
				<v-icon class="rc-menuPanelBtn">menu</v-icon>
			</v-flex>
		</v-layout>
		<!-- List Menu -->
		<v-navigation-drawer id="rc-menuPanel" v-model="drawer" fixed class="rc-menuDrawer">
			<v-list v-if="tipo_menu === 3" class="transparent pa-2">
				<v-flex v-for="(panelSolicitanteMenu, index) in panelSolicitanteMenus" :key="`${panelSolicitanteMenu.icon}-${index}`">
					<!-- Mi Panel -->
					<v-list-tile  v-if="valMisDatos && panelSolicitanteMenu.text == 'Mis datos'" 
					class="v-list-item rc-listItem-pad" :class="stateOptionMisDatos" :active-class="activeClassMenuAlert" :to="panelSolicitanteMenu.to.path">
						<div style="text-align: center; width: 100%; line-height: 1; display: flex; flex-direction: row; align-items:center; ">
							<div style="flex-basis: 90%;" class="custom-margin">
								<span class="rc-listItem-img material-icons-outlined " style="height:26px;width:26px">{{ panelSolicitanteMenu.icon }}</span>
								<div class="rc-listItem-title ">{{ panelSolicitanteMenu.text }}</div>
							</div>
							
						</div>
					</v-list-tile>
					<v-list-tile  v-else-if="valUsuario && panelSolicitanteMenu.text == 'Mis claves'" 
					class="v-list-item rc-listItem-pad" :class="stateOptionMisClaves" :active-class="activeClassMenuAlerta" :to="panelSolicitanteMenu.to.path">
						<div style="text-align: center; width: 100%; line-height: 1; display: flex; flex-direction: row; align-items:center; ">
							<div style="flex-basis: 90%;" class="custom-margin">
								<span class="rc-listItem-img material-icons-outlined " style="height:26px;width:26px">{{ panelSolicitanteMenu.icon }}</span>
								<div class="rc-listItem-title ">{{ panelSolicitanteMenu.text }}</div>
							</div>
						</div>
					</v-list-tile>
					<v-list-tile v-else-if="!user_valido" 
					:disabled="panelSolicitanteMenu.text != 'Mi panel' && panelSolicitanteMenu.text != 'Mis datos' && panelSolicitanteMenu.text != 'Mis empresas' && panelSolicitanteMenu.text != 'Mis claves' && panelSolicitanteMenu.text != 'Anticipar facturas'"					
					class="v-list-item rc-list-item rc-listItem-pad " 
					:class="panelSolicitanteMenu.text != 'Mi panel' && panelSolicitanteMenu.text != 'Mis datos' && panelSolicitanteMenu.text != 'Mis empresas' && panelSolicitanteMenu.text != 'Mis claves' && panelSolicitanteMenu.text != 'Anticipar facturas' ? 'no-ctive' : ''"
					:active-class="activeClassMenu" 
					:to="panelSolicitanteMenu.to.path">
						<div style="text-align: center; width: 100%; line-height: 1;">
							<span class="rc-listItem-img material-icons-outlined" style="height:26px;width:26px">{{ panelSolicitanteMenu.icon }}</span>
							<div class="rc-listItem-title">{{ panelSolicitanteMenu.text }}</div>
						</div>
					</v-list-tile>
					<v-list-tile v-else  class="v-list-item rc-list-item rc-listItem-pad" :active-class="activeClassMenu" :to="panelSolicitanteMenu.to.path">
						<div style="text-align: center; width: 100%; line-height: 1;">
							<span class="rc-listItem-img material-icons-outlined" style="height:26px;width:26px">{{ panelSolicitanteMenu.icon }}</span>
							<div class="rc-listItem-title">{{ panelSolicitanteMenu.text }}</div>
						</div>
					</v-list-tile>
				</v-flex>
			</v-list>
			<v-list v-if="tipo_menu === 2" class="transparent pa-2">
				<!-- Mi Panel -->
				<div v-for="(panelInversionistaMenu, index2) in dashboardLogeado" :key="`${panelInversionistaMenu.icon}-${index2}`">
					<v-list-tile v-if="!panelInversionistaMenu.categories" class="v-list-item rc-list-item rc-listItem-pad" :active-class="activeClassMenu" :to="panelInversionistaMenu.to.path ? panelInversionistaMenu.to.path : {name: panelInversionistaMenu.to.name}">
						<div style="text-align: center; width: 100%; line-height: 1;">
							<span class="rc-listItem-img material-icons-outlined" style="height:26px;width:26px">{{ panelInversionistaMenu.icon }}</span>
							<div class="rc-listItem-title">{{ panelInversionistaMenu.text }}</div>
						</div>
					</v-list-tile>
					<v-list-group v-else class="rc-list-item">
						<template v-slot:activator>
							<div style="text-align: center; width: 100%; line-height: 1;">
								<span class="rc-listItem-img material-icons-outlined" style="height:26px;width:26px">{{ panelInversionistaMenu.icon }}</span>
								<div class="rc-listItem-title">{{ panelInversionistaMenu.text }}</div>
							</div>
						</template>
						<div v-for="(category, index3) in panelInversionistaMenu.categories" :key="`${category.icon}-${index3}`">
							
							<v-list-tile v-if="category.text != 'Cartola Mensual'" class="v-list-item rc-list-item rc-listItem-pad" :active-class="activeClassMenu" :to="{name: category.to.name}">
								<div style="text-align: center; width: 100%; line-height: 1;">
									<span class="rc-listItem-img material-icons-outlined" style="height:26px;width:26px">{{ category.icon }}</span>
									<div class="rc-listItem-title">{{ category.text }}</div>
								</div>
							</v-list-tile>
							
							<v-list-tile v-else-if="category.text == 'Cartola Mensual' && showtable" class="v-list-item rc-list-item rc-listItem-pad" :active-class="activeClassMenu" :to="{name: category.to.name}">
								<div style="text-align: center; width: 100%; line-height: 1;">
									<span class="rc-listItem-img material-icons-outlined" style="height:26px;width:26px">{{ category.icon }}</span>
									<div class="rc-listItem-title">{{ category.text }}</div>
								</div>
							</v-list-tile>
						</div>
						
					</v-list-group>
				</div>
			</v-list>
		</v-navigation-drawer>
	</div>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
	import { mapState } from "vuex";
	import { panelSolicitanteMenus, panelInversionistaMenus, dashboardLogeado } from "@/models/DashboardLinks";
	
	@Component({
		props: {
			// datos
			tipo_menu: Number
        },
		computed: {
			...mapState(["user", "setOpenMenuPanel", "cuentas_bancarias", "user_valido", "estado_sunat", "businesSelected", "estado_cavali"]),
			activeClassMenu() {
				return "rc-menuPanelActive";
			},
			showtable(){
				return this.$store.state.user.id == 6984 || this.$store.state.user.id == 28 || this.$store.state.user.id == 167 || this.$store.state.user.id == 274 || this.$store.state.user.id == 123;
			},
			activeClassMenuAlert() {
				if (!this.cuentas_bancarias) {
					return "rc-listItem-alert-active";
				} 
				else {
					return '';
				}
			},
			stateOptionMisDatos() {
				if (!this.cuentas_bancarias) {
				return 'rc-listItem-alert rc-text-color-alert';
				} else {
				return '';	
				}
			},
			activeClassMenuAlerta() {
				if ((!this.user_valido && (this.estado_sunat == 3 || this.estado_sunat == null)) || (this.estado_cavali == 3 || this.estado_cavali == null) ) {
					return "rc-listItem-alert-active";
				}
				else if((!this.user_valido && this.estado_sunat == 2) || this.estado_cavali == 2){
					return "rc-listItem-warning-active";
				} 
				else {
					return '';
				}
			},
			stateOptionMisClaves() {
				if ((!this.user_valido && (this.estado_sunat == 3 || this.estado_sunat == null)) || (this.estado_cavali == 3 || this.estado_cavali == null)) {
				return 'rc-listItem-alert rc-text-color-alert';
				}
				else if((!this.user_valido && this.estado_sunat == 2) || this.estado_cavali == 2){
					return "rc-listItem-warning rc-text-color-warning";
				}  
				else {
					return '';	
				}
			},
			valMisDatos() {
				return !this.cuentas_bancarias && this.businesSelected != null; 
			},
			valUsuario(){
				return this.businesSelected != null;
			}
			
		},
		data() { // Data Menu
			return {
				// Menu Solicitante general
				panelSolicitanteMenus,
				panelInversionistaMenus,
				dashboardLogeado,
				title: null,
				drawer: false,
				responsive: false,
				dark: true,
			}
		},
		// beforeMount() {}, // Antes de Montar el componente
		// beforeUpdate(){}, // Antes de Actualizar el componente
		mounted(){
			 // @ts-ignore
			this.onResponsive();
			// @ts-ignore
			window.addEventListener("resize", this.onResponsive);
		},
		methods:{
			onResponsive() {
				if (window.innerWidth >= 1264){
					this.hasClassMenu();
					this.drawer = true;
					if(this.$props.tipo_menu === 2 || this.$props.tipo_menu === 3){ // Modifica variable para expandir o cerrar el menu lateral Inversionista
						this.$store.commit("setOpenMenuPanel", true);
					}
				}
				if (window.innerWidth < 960) {
					this.$data.responsive = true;
				} else {
					this.$data.responsive = false;
				}
			},
			setOpenMenu(){
				if(this.$data.drawer){
					this.drawer = false;
					this.$store.commit("setOpenMenuPanel", false);
				}else{
					this.drawer = true;
					this.$store.commit("setOpenMenuPanel", true);
				}
			},
			hasClassMenu(){
				if(document.getElementById('rc-menuPanel').className.match(/\bv-navigation-drawer--open\b/)){
					this.drawer = true;
					this.$store.commit("setOpenMenuPanel", true);
				}else{
					this.drawer = false;
					this.$store.commit("setOpenMenuPanel", false);
				}
			}
		}
	})
	export default class SideBarPanel extends Vue {}
</script>

<style lang="scss" scoped>

	.no-ctive::v-deep a{
		opacity: 0.5;
	}
	.rc-menuPanelBtn{
		cursor: pointer; 
		color: $rc-prim-n1;
	}

	.rc-listItem-title{
		font-weight: 600;
	}

	.v-list{
		padding: 5px 0px !important;
	}
	
	.rc-listItem-pad{
		padding: 5px 5px;
	}
	.rc-text-color-alert{
		color: #E83F29;
	}

	.rc-text-color-warning{
		color: #FFD541 !important;
	}
	
	/* Menu */
	.rc-list_ico{
		height: 20px;
		width: 20px;
	}
	.rc-right-bar{
		height: calc(100vh - 64px);
		background-color: $rc-escala5;
	}

	.rc-noml{
		margin-left: 0 !important;
	}

	.rc-menuDrawer{
		margin-top:64px !important;
		z-index:1; 
		width:150px !important; 
		height: calc( 100vh - 64px ) !important; 
		min-height: calc( 100vh - 64px ) !important; 
	}
	// Breakpoints 
	@each $breakpoint in $breakpoints_screen_text{
		@include breakpoint_media_queries($breakpoint){
			@if $breakpoint == "x-large"{
				
			}@else if $breakpoint == "large"{
				
			}@else if $breakpoint == "medium-to-large"{
				.rc-menuDrawer{
					margin-top: 0 !important;
					z-index: 5;
					width: 300px !important;
					height: 100vh !important; 
					min-height: 100vh !important;
				}
				.rc-listItem-img{
					float: left;
				}
				.rc-listItem-title{
					float: left;
					line-height: 2;
					margin-left: 10px;
				}
			}@else if $breakpoint == "small-to-medium"{
				.rc-menuDrawer{
					margin-top: 0 !important;
					z-index: 5;
					width: 300px !important;
					height: 100vh !important; 
					min-height: 100vh !important;
				}
				.rc-listItem-img{
					float: left;
				}
				.rc-listItem-title{
					float: left;
					line-height: 2;
					margin-left: 10px;
				}
			}@else if $breakpoint == "small"{
				.rc-menuDrawer{
					margin-top: 0 !important;
					z-index: 5;
					width: 300px !important;
					height: 100vh !important; 
					min-height: 100vh !important; 
				}
				.rc-listItem-img{
					float: left;
				}
				.rc-listItem-title{
					float: left;
					line-height: 2;
					margin-left: 10px;
				}
			}
		}
	}
	@media (min-width:1264px) {
		.custom-margin{
			margin-left: 5px;
		}
	}
	@media (max-width:1264px) {
		.custom-margin{
			margin-left: 0px;
		}
	}
</style>
