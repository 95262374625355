import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import api from './api';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    menu: null,
    user: null,
    token: null,
    expireTime: null,
    gatewayTransaction: null,
    lastCod: null,
    onlyActives: true,
    gatewayTransactionConfirmed: false,
    transactionIsPendingPayment: false,
    pendingPaymentsIds: [],
    transactionProjectId: null,
    business: null,
    requesting: null,
    proyectId: null,
    currentInvestmentId: null,
    isLastInvestment: false,
    lastSimulation: null,
    amountFinanced: null,
    questionId: null,
    routes: null,
    openMenuPanel: false,
    showPanelInv:null,
    businesSelected:null,
    cuentas_bancarias:null,
    user_valido: null,
    estado_sunat: null,
    estado_cavali: null
  },
  mutations: {
    setQuestionId(state, id) {
      state.questionId = id;
    },
    clearQuestionId(state) {
      state.questionId = null;
    },
    hideOrNot(state, newState) {
      state.onlyActives = newState;
    },
    setCurrentInvestment(state, { investmentId, isLastInvestment, proyectId }) {
      state.currentInvestmentId = investmentId;
      state.isLastInvestment = isLastInvestment;
      state.proyectId = proyectId;
    },
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
    },
    setBusiness(state, business) {
      state.business = business;
    },
    setRequesting(state, request) {
      state.requesting = request
    },
    setEmpresasCount(state, count) {
      // @ts-ignore
      state.user.empresas_count = count;
    },
    setExpireTime(state, expireTime) {
      state.expireTime = expireTime;
    },
    deleteSimulation(state) {
      state.lastSimulation = null;
    },
    logout(state) {
      state.user = null;
      state.token = null;
      state.expireTime = null;
      state.lastSimulation = null;
      state.estado_sunat = null;
      state.user_valido = null;
      state.cuentas_bancarias = null;
      state.estado_cavali = null;
      api.logout()
      .then((resolve) =>{
        state.user = null;
        state.token = null;
        state.expireTime = null;
        state.lastSimulation = null;
        state.estado_sunat = null;
        state.estado_cavali = null;
        state.user_valido = null;
        state.cuentas_bancarias = null;
      }).catch((error)=>{
        console.log(error)
      })
    
    },
    logAndReconnect(state) {
      state.user = null;
      state.expireTime = null;
      state.lastSimulation = null;
    },
    setGatewayTransaction(state, transaction) {
      state.gatewayTransaction = transaction;
      state.gatewayTransactionConfirmed = false;
      state.transactionIsPendingPayment = false;
    },
    resetGatewayTransactionConfirmation(state) {
      state.gatewayTransactionConfirmed = false;
    },
    setLastCod(state, cod) {
      state.lastCod = cod;
    },
    confirmGatewayTransaction(state) {
      state.gatewayTransactionConfirmed = true;
    },
    setTransactionPendingPayment(state) {
      state.transactionIsPendingPayment = true;
    },
    setTransactionPaymentType(state, id) {
      // @ts-ignore
      state.gatewayTransaction.pago_deta_tipo_id = id;
    },
    setPendingPaymentsIds(state, ids) {
      state.pendingPaymentsIds = ids;
    },
    setTransactionProjectId(state, id) {
      state.transactionProjectId = id;
    },
    setLastSimulation(state, simulation) {
      state.lastSimulation = simulation;
    },
    setAmountFinanced(state, amountFinanced) {
      state.amountFinanced = amountFinanced;
    },
    setRoutes(state, route) {
      state.routes = route;
    },
    setOpenMenuPanel(state, estado){
      state.openMenuPanel = estado;
    },
    setMenu(state,payload){
      state.menu = payload
    },
    setShowPanelInv(state,payload){
      state.showPanelInv = payload
    },
    setBusinessSelected(state,payload){
      state.businesSelected = payload
    },
    setCuentasBancarias(state,payload){
      state.cuentas_bancarias = payload
    },
    setUserValid(state,payload){
      state.user_valido = payload
    },
    setEstadoSunat(state,payload){
      state.estado_sunat = payload
    },
    setEstadoCavali(state,payload){
      state.estado_cavali = payload
    },
  },
  getters: {
    canShowPendingPayments: state => (ids: Array<number>) => {
      return false;
      // @ts-ignore
      // return !(ids.map((id: number) => state.pendingPaymentsIds.includes(id)).every((val) => val == true) && ids.length == state.pendingPaymentsIds.length)
    }
  },
  actions: {}
});
