





























































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  TitleTemplateDashboard,
  SelectedCountry,
  RedCapital
} from "@/configuration";
import API from "@/api";
import moment from "moment";
import {mapState} from 'vuex';
import TableResponsive from "@/components/table/TableResponsive.vue";
import DatatableFilterPlugin from "@/filters";
// @ts-ignore
import Countdown from "@/components/Countdown.vue";
// @ts-ignore
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

Vue.use(DatatableFilterPlugin);
@Component({
  metaInfo: {
    title: "Transferencias pendientes",
    titleTemplate: TitleTemplateDashboard,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang
    }
  },
  computed: {
    ...mapState(["user", "setOpenMenuPanel"]),
    setOpenMenuPanel(){
      return this.$store.state.openMenuPanel;
    },
    filteredDatatable() {
      return this.$data.expPendingPayments.filter((i: any) =>
        Object.keys(this.$data.filters).every(
          f =>
            this.$data.filters[f].length < 0 ||
            this.$data.filters[f].includes(i[f])
        )
      );
    },
    filtered2() {
      return this.$data.expPendingPayments.filter((i: any) => {
        // console.log(i.proyecto.id)
        // console.log(i.proyecto.nombre)
        // console.log(i.cod)
        // console.log(`${i.proyecto.tir} %`)
        // console.log(i.monto_a_invertir)
        // console.log(i.monto_prestamo)
        // console.log(i.derechos)
        // //@ts-ignore
        // console.log(dataPendiente)
        // //@ts-ignore
        // console.log(dataMonto)
        // console.log(i.created_at)
        // Props 
				let propsObjTableAll = {
					style: `text-align: center;
							font-size: 0.7rem;
							font-weight: 500;
							color: #8b8b8b`
				}
				// Props 
				let propsObjTableButtons = {
					style: `display: flex;
							justify-content: center;`
				}
				let dataPendiente = null;
				if(i.pago_inversionista != null){
					dataPendiente = ( i.monto_prestamo + i.derechos ) - i.pago_inversionista.pago.monto_confirmado
				}else{
					dataPendiente = i.monto_prestamo + i.derechos
				}
				let dataMonto = null;
				if(i.pago_inversionista){
					dataMonto = i.pago_inversionista.pago.monto_no_confirmado_calculado
				}else{
					dataMonto = 0
				}
				// Formateando Data
				// @ts-ignore
				let disPago = !this.disabledbtnes(i.time_left);
				// @ts-ignore
				let disAnular = !this.disabledbtnesanular(i.time_left, i.verificado, i.pagado);
        
				let objTable = {
					id: {
						value	: i.proyecto.id,
						label 	: 'ID',
						props 	: propsObjTableAll
					},
					proyecto: {
						value	: i.proyecto.nombre,
						label 	: 'Proyecto',
						props 	: propsObjTableAll
					},
					codigo: {
						value	: i.cod,
						label 	: 'Codigo inversión',
						props 	: propsObjTableAll
					},
					tir: {
						value	: `${i.proyecto.tir} %`,
						label 	: '% TIR',
						props 	: propsObjTableAll
					},
					inversion: {
						// @ts-ignore
						value	: i.proyecto.proyecto_moneda.moneda.simbolo + i.monto_a_invertir,
						label 	: 'Monto inversión',
						props 	: propsObjTableAll
					},
					prestamo: {
						// @ts-ignore
						value	: i.proyecto.proyecto_moneda.moneda.simbolo + i.monto_prestamo,
						label 	: 'Préstamo',
						props 	: propsObjTableAll
					},
					derechos: {
						// @ts-ignore
						value	: i.proyecto.proyecto_moneda.moneda.simbolo + i.derechos,
						label 	: 'Derechos proyecto',
						props 	: propsObjTableAll
					},
					pendiente: {
						// @ts-ignore
						value	:i.proyecto.proyecto_moneda.moneda.simbolo + dataPendiente,
						label 	: 'Saldo pendiente',
						props 	: propsObjTableAll
					},
					monto: {
						// @ts-ignore
						value	:i.proyecto.proyecto_moneda.moneda.simbolo + dataMonto,
						label 	: 'Monto por confirmar',
						props 	: propsObjTableAll
					},
					creacion: {
						value	: moment(i.created_at).format("DD-MM-YYYY"),
						label 	: 'Fecha creación',
						props 	: propsObjTableAll
					},
					acciones: {
						value: '',
						label: 'Acciones',
						props 	: propsObjTableButtons,
						buttons: [
							{
								funcion: 'showComentariosChild', // Cancelar
								icoFont: 'do_disturb',
								props: {
									disabled : disAnular
								},
								data: i.id,
								tooltip: 'Anular transferencia'
							},
						]
					}
				};
				i.datosEnTabla = objTable;
        return (
          this.$data.name.includes(i.proyecto.nombre) ||
          this.$data.id.includes(i.proyecto.id)
        );
      });
    }
  },
  data() {
    return {
      penpayment:null,
      comentario:false,
      SelectedCountry,
      RedCapital,
      anularCementarios:"",
      filters: {
        id: null,
        name: null
      },
      id: "",
      name: "",
      search: "",
      show_expired: false,
      expPendingPayments: null,
      paginationD: {
        sortBy: "created_at",
        descending: true
      },
      paginationI: {
        rowsPerPage: 4
      },
      rowsPerPageItems: [8, 12, 20],
      tableData:{
        headers: [
					{
						text: "ID",
						align: "center",
						value: "proyecto_id",
					},
					{
						text: "Proyecto",
						align: "center",
						value: "proyecto.nombre",
					},
					{
						text: "Codigo inversión",
						align: "center",
						value: "rc_codigo",
					},
					{
						text: "% TIR",
						align: "center",
						sortable: false,
					},
					{
						text: "Monto inversión",
						align: "center",
						sortable: false,
					},
					{
						text: "Préstamo",
						align: "center",
						value: "monto_inversion",
					},
					{
						text: "Derechos proyecto",
						align: "center",
						value: "derechos",
					},
					{
						text: "Saldo pendiente",
						align: "center",
						value: "saldo_pendiente",
					},
					{
						text: "Monto por confirmar",
						align: "center",
						sortable: false,
					},
					{
						text: "Fecha creación",
						align: "center",
						value: "created_at",
					},
					{
						text: "Acciones",
						align: "center",
						sortable: false,
					}
				],
				msjEmpty: 'No existen registros en tu cuenta',
				rowsPerPage: {
					txt: 'Registro por página',
					itm: [10, 25, 50, { text: 'Todos', value: -1 }]
				},
				pagination: {
					sortBy: "created_at",
					descending: true,
					page: 1
				},
      },
      headers: [
        {
          text: "Acciones",
          align: "center",
          sortable: false
        },
        {
          text: "No invertir",
          align: "center",
          sortable: false
        },
        {
          text: "Detalle pagos",
          align: "center",
          sortable: false
        },
        {
          text: "ID",
          align: "center",
          value: "proyecto_id"
        },
        {
          text: "Proyecto",
          align: "center",
          value: "proyecto.nombre"
        },
        {
          text: "Fecha creación",
          align: "center",
          value: "created_at"
        },
        {
          text: "Codigo inversión",
          align: "center",
          value: "rc_codigo"
        },
        {
          text: "% TIR",
          align: "center",
          sortable: false
        },
        {
          text: "Préstamo",
          align: "center",
          value: "monto_inversion"
        },
        {
          text: "Derechos proyecto",
          align: "center",
          value: "derechos"
        },
        {
          text: "Saldo pendiente",
          align: "center",
          value: "saldo_pendiente"
        },
        {
          text: "Monto por confirmar",
          align: "center",
          sortable: false
        },
        {
          text: "Tiempo restante",
          align: "center",
          value: "time_left"
        }
      ],
      pendingPayments: null,
      rules: {
            notEmpty: [
                (v: any) => !!v || "Ingrese un valor",
                (v: any) => v.length > 9 || "Minimo 10 caracteres"
                ],
          }
    };
  },         
  async beforeMount() {
    let pendingPayments = await API.getPendingPayments();
    console.log(pendingPayments)
    
    pendingPayments.forEach((el,i)=>{
      el.monto_prestamo = parseFloat(el.monto_prestamo)
      el.derechos = parseFloat(el.derechos)
      if(el.pago_inversionista != null){
        el.pago_inversionista.pago.monto_confirmado = parseFloat(el.pago_inversionista.pago.monto_confirmado)
      }
    }) 
    this.$data.pendingPayments = pendingPayments != null ? pendingPayments : [];
    this.$data.pendingPayments = this.$data.pendingPayments.map(
      (investment: any) => {
        investment["monto_inversion"] =
          investment.monto + investment.saldo_pendiente;
        investment["rc_codigo"] = `${investment.proyecto_id}`;
        return investment;
      }
    );
    this.$data.expPendingPayments = this.$data.pendingPayments.filter(
      (i: any) =>
        i.reservado == 1 ||
        moment(new Date()) <= moment(i.time_left).add(6, "days")
    );
    this.$data.filters.id = this.$data.expPendingPayments
      .map((investment: any) => investment.proyecto.id)
      .filter((val: any) => val !== null);
    this.$data.filters.name = this.$data.expPendingPayments
      .map((investment: any) => investment.proyecto.nombre)
      .filter((val: any) => val !== null);
    // console.log(this.$data.pendingPayments)
    // console.log(this.$data.expPendingPayments)
    let pdp = this.$data.pendingPayments.map((i: any) => {
      return {
        payment_id: i.proyecto_id,
        payment_proyecto: i.proyecto.nombre,
        payment_fecha: moment(i.proyecto.created_at).format("DD-MM-YYYY"),
        payment_codigo: i.cod,
        payment_tir: i.proyecto.tir + "%",
        // @ts-ignore
        payment_prestamo: this.$options.filters.currency(i.monto_prestamo),
        // @ts-ignore
        payment_derechos: this.$options.filters.currency(i.derechos),
        payment_pendiente:
          i.pago_inversionista != null
            // @ts-ignore
            ? this.$options.filters.currency(
                i.monto_prestamo +
                  i.derechos -
                  i.pago_inversionista.pago.monto_confirmado
              )
            // @ts-ignore
            : this.$options.filters.currency(i.monto_prestamo + i.derechos)
      };
    });
    
    this.$data.penpayment = this.$data.expPendingPayments.map((i: any) => {
      return {
        Proyecto_id: i.proyecto_id,
        Proyecto: i.proyecto.nombre,
        Fecha: moment(i.proyecto.created_at).format("DD-MM-YYYY"),
        Codigo: i.cod,
        Tir: i.proyecto.tir + "%",
        // @ts-ignore
        Inversion:this.$options.filters.currency(i.monto_a_invertir),
        // @ts-ignore
        Prestamo: this.$options.filters.currency(i.monto_prestamo),
        // @ts-ignore
        Derechos: this.$options.filters.currency(i.derechos),
        Pendiente:
          i.pago_inversionista != null
            // @ts-ignore
            ? this.$options.filters.currency(
                i.monto_prestamo +
                  i.derechos 
              )
            // @ts-ignore
            : this.$options.filters.currency(i.monto_prestamo + i.derechos)
      };
    });

  },
  methods: {
    cerrarmodal(id:any){
      this.$data.comentario = false
    },
    showComentarios(id:any){
       this.$data.comentario = true
       this.$data.idproyect = id
    },
    anularPendingPayment(){
      let id = this.$data.idproyect,
          comentario = this.$data.anularCementarios
      API.AnularPendingPayments(id,comentario)
      .then((res:any)=>{
        this.$data.comentario = false
        this.$data.anularCementarios = ''
        //@ts-ignore
        this.updateRegister()
      })
    },
     disabledbtnes(i:any){
     return moment(i) > moment(new Date().getTime()) ? true : false
    },
    disabledbtnesanular(fecha:any,ver:any,pagado:any){
        if( (moment(fecha).format( 'DD-MM-YYYY h:mm:ss') > moment(new Date().getTime()).format( 'DD-MM-YYYY h:mm:ss') ) || (ver == 1 && pagado == 1) || (ver == 0)){
          return true
        }else if(ver == 1 && pagado == 0){
         return false
        }
    },
    getPendingPaymentAmount(investment, status: number) {
      if (investment.pago_inversionista == null) {
        return 0;
      } else {
        return investment.pago_inversionista.pago.pago_detalles
          .filter((pago: any) => pago.confirmado == status)
          .map((pago: any) => parseFloat(pago.monto))
          .reduce((last: number, current: number) => last + current, 0);
      }
    },
    timeLeftToHours(createdAt, timeLeft) {
      let startTime = Date.now();
      return startTime;
      /*let endTime   = moment(timeLeft)
      let duration = moment.duration(endTime.diff(startTime));

      let days    = duration.asDays()
      let hours   = duration.asHours()// % 60
      let minutes = duration.asMinutes()// % (60 * 60)
      let seconds = duration.asSeconds()// % (60 * 60 * 60)

      return `${days}:${hours}:${minutes}:${seconds}`*/
    },
    resetFilters() {
      this.$data.id = "";
      this.$data.name = "";
    },
    async updateRegister(){
      let pendingPayments = await API.getPendingPayments();
    this.$data.pendingPayments = pendingPayments != null ? pendingPayments : [];
    this.$data.pendingPayments = this.$data.pendingPayments.map(
      (investment: any) => {
        investment["monto_inversion"] =
          investment.monto + investment.saldo_pendiente;
        investment["rc_codigo"] = `${investment.proyecto_id}`;
        return investment;
      }
    );
    this.$data.expPendingPayments = this.$data.pendingPayments.filter(
      (i: any) =>
        i.reservado == 1 ||
        moment(new Date()) <= moment(i.time_left).add(6, "days")
    );
    this.$data.filters.id = this.$data.expPendingPayments
      .map((investment: any) => investment.proyecto.id)
      .filter((val: any) => val !== null);
    this.$data.filters.name = this.$data.expPendingPayments
      .map((investment: any) => investment.proyecto.nombre)
      .filter((val: any) => val !== null);
    }
  },
  components: {
    Countdown,
    TableResponsive
  }
})
export default class PendingPayments extends Vue {}
