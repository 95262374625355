































































































































































import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import DniField from "@/components/DniFieldBusiness.vue";
import {
  Configuration,
  SelectedCountry,
  RedCapital,
  TitleTemplateDashboard,
} from "@/configuration";
import API from "@/api";
import { isRutValid } from "@/utils";
import { EventBus } from "@/main";
import { mapState } from "vuex";
import { DniTrim } from "@/utils";
import Business from './Business.vue';

@Component({
  computed: { 
    ...mapState(["user", "setOpenMenuPanel"]),
    setOpenMenuPanel(){
      return this.$store.state.openMenuPanel;
    } 
  },
  metaInfo: {
    title: "Crear empresa",
    titleTemplate: TitleTemplateDashboard,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    },
  },
  data() {
    return {
      isLoading: false,
      showCheck: {
        desc: false,
      },
      textAreaMax150: '',
      dialog2: false,
      empresa:'',
      business:'crear',
      otherRegisterBusiness:false,
      SelectedCountry,
      RedCapital,
      valid: true,
      loading: false,
      restore:'',
      loading2:false,
      disabledName: true,
      rutValid: false,
      dialog: false,
      errorMessage: null,
      progress:'',
      errorRut: null,
      errorPhone: null,
      errorDescription: null,
      errorRut404: null,
      rutInfo: null,
      rutInfo2: null,
      sale: [
        {
          id: 1,
          text: "Personas",
        },
        {
          id: 2,
          text: "Empresas",
        },
        {
          id: 3,
          text: "Ambas",
        },
      ],
      form: {
        name: "",
        dni: "",
        phone: null,
        address: "",
        description: "",
        radios: "siiPassword",
        folder: null,
        id: this.$store.state.user.id,
        sales: 2
      },
      plh: '',
      rules: {
        name: [(v: any) => !!v || "Nombre es requerido"],
        dni: [
          // @ts-ignore
          (v: any) => !!v || "RUC es requerido",
        ],
        phone: [
          (v: any) => !/[^\d]/gim.test(v) || "Tiene que ser sólo números",
        ],
        address: [(v: any) => !!v || "Dirección es requerido"],
        debt: [(v: any) => !/[^\d]/gim.test(v) || "Tiene que ser sólo números"],
        description: [
          (v: any) =>
            v.length <= 255 ||
            "Descripción no puede exceder los 255 caracteres",
        ],
        sales: [(v: any) => !!v || "Selecciona una opción"],
        noVacioTextAreaGeneral: [
          // @ts-ignore
          v => {
            if(!v || v.trim() === ''){
              return 'Campo requerido'
            }else if(v.trim().length < 10){
              return 'Ingrese al menos 10 caracteres'
            }else if(v.trim().length >= 150){
              this.$data.form.description = this.$data.form.description.trim();
              return true
            }else{
              return true
            }
          }
        ],
      },
      empresaId: null,
    };
  },
  watch: {
    'form.description' (val: any){
      if(val != null && val.trim().length <= 150 && val.trim().length > 9){
        if(this.$data.textAreaMax150 != ''){
          this.$data.textAreaMax150 = '';
        }
        if(val.trim().length == 150){
          this.$data.textAreaMax150 = val 
        }
        this.$data.showCheck.desc = true;
      } else {
        this.$data.showCheck.desc = false;
      }
      if(val != null && val.trim().length > 150){
        this.$data.form.description = '';
        setTimeout(() => {
          this.$data.form.description = this.$data.textAreaMax150;	
        });
        this.$data.showCheck.desc = false;
      }
    },
    "form.radios"(val: any) {
      if (val == "default") {
        this.$data.form.siiPassword = "sin-clave";
        this.$data.form.folder = null;
      }
    },
    "form.dni"() {
      // TODO: AGREGAR VALIDACION RUC
    if (SelectedCountry == "PE") {
        // @ts-ignore
        if (this.$data.form.dni.length == 11) {
          this.$data.errorRut = null;
          this.$data.plh= 'Verificando información',
          this.$data.restore='restore',
          this.$data.value='',
          this.$data.progress=''
          this.$data.loading2=true
          if (this.$data.form.dni.length == 11) {
            EventBus.$emit("dni-loading");
            this.$data.empresaId = null;
            API.factoring.buscarEmpresaRUC(this.$data.form.dni)
            .then((res:any)=>{
              this.$data.restore='';
              this.$data.value='';
              this.$data.progress='';
              this.$data.loading2=false
              if(res.status == 200 && res.message == 'Empresa existe pero ya está asignada a otro usuario.'){
                this.$data.otherRegisterBusiness = true;
                this.$data.form.name = "";
                this.$data.form.businessName = null;
                this.$data.form.order = null;
                this.$data.rutInfo = null;
                this.$data.rutInfo2 = null;
                EventBus.$emit("snack-success", "RUC validado");
                EventBus.$emit("dni-success");
              }
              else if(res.status == 200 && res.message == 'Crear'){
                this.$data.business = 'crear'
                EventBus.$emit("snack-success", "RUC validado");
                EventBus.$emit("dni-success");
              }
              else if(res.status== 200 && res.message == 'Empresa disponible.'){
                this.$data.empresa = res.data;
                this.$data.business = 'actualizar';
                this.$data.empresaId = res.data.id;
                EventBus.$emit("snack-success", "RUC validado");
                EventBus.$emit("dni-success");
              }
             
            }).finally(()=>{
              this.$data.plh = '';
            })
          }
        } else {
            this.$data.restore='';
            this.$data.value='';
            this.$data.progress='';
            this.$data.loading2=false
          this.$data.errorRut = null;
          this.$data.disabledName = true;
          this.$data.form.name = "";
          (this.$data.form.businessName = null), (this.$data.form.order = null);
          this.$data.rutInfo = null;
          this.$data.rutInfo2 = null;
          EventBus.$emit("dni-error");
        }
      } else {
        this.$data.form.businessName = "Default";
        this.$data.form.order = "Default";
        this.$data.form.siiPassword = "sin-clave";
      }
    },
  },
  methods: {
      actualizar(){
       // @ts-ignore
      // if (this.$refs.form.validate()) {
      //   this.$data.valid = true;
      //   this.$data.errorMessage = null;
      //   this.$data.empresa.nombre = this.$data.form.name;
      //   API.factoring.actualizardatosEmpresa(this.$data.empresa)
      //   .then((res:any)=>{
      //     this.$data.dialog = true;
      //       // this.$store.commit("setBusinessSelected", res.data);
      //       this.$store.commit("setBusiness", res.data.id);
      //       this.$store.commit("setEmpresasCount", +1);
      //       setTimeout(() => {
      //         this.$router.push({
      //           path: "/panel-facto/estadisticas",
      //         });
      //       }, Configuration.Redirection.timeout);
      //   })
      // }
    },
    validate() {
      // @ts-ignore
      if (this.$refs.form.validate()) {
        this.$data.valid = true;
        this.$data.isLoading = true;
        this.$data.errorMessage = null;

        let theTruth: any = {
          name: "nombre",
          dni: "rut",
          phone: "telefono",
          address: "direccion",
          description: "descripcion",
          sales: "empresa_target_id",
          id: "user_id",
        };

        let payload: any = {};
        for (let key in this.$data.form) {
          if (theTruth[key] != undefined) {
              payload[theTruth[key]] = this.$data.form[key];
          }
        }
        if(this.$data.business === 'crear'){
          API.createBusiness(payload)
            .then((res: any) => {
              this.$data.isLoading = false;
              this.$data.dialog = true;
              this.$store.commit("setBusinessSelected", res);
              this.$store.commit("setBusiness", res.id);
              this.$store.commit("setEmpresasCount", +1);
              setTimeout(() => {
                this.$router.push({
                  path: "/panel-facto/empresas",
                });
              }, Configuration.Redirection.timeout);
            })
            .catch((err: any) => {
              this.$data.isLoading = false;
              this.$data.errorMessage = err.data.message;
              EventBus.$emit("snack-error", "Revisa los errores");
            });
        }
        else if(this.$data.business === 'actualizar'){
          payload.id = this.$data.empresaId;
          API.factoring.actualizardatosEmpresa(payload)
            .then((res:any)=>{
              this.$data.dialog = true;
              this.$store.commit("setBusinessSelected", res.data);
              this.$store.commit("setBusiness", res.data.id);
              this.$store.commit("setEmpresasCount", +1);
              setTimeout(() => {
                this.$router.push({
                  path: "/panel-facto/empresas",
                });
              }, Configuration.Redirection.timeout);
          })
        }
      }
    },
    fileUpload() {
      // @ts-ignore
      if (this.$refs.pdf.files.length > 0) {
        // @ts-ignore
        this.$data.form.folder = this.$refs.pdf.files[0];
      }
    },
  },
  components: {
    DniField,
  },
})
export default class CreateBusiness extends Vue {}
