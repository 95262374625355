










import { Component, Vue, Prop } from "vue-property-decorator";
import API from "@/api";

@Component({
  data() {
    return {
      companySelected: null,
      business: [
        {
          name: "Cargando empresas..."
        }
      ]
    };
  },
  watch: {
    companySelected(val) {
      this.$emit("input", val);
    },
    value(val) {
      this.$data.companySelected = val;
    }
  },
  async beforeMount() {
    // @ts-ignore
    let business: any[] = await API.getBusiness();
    this.$data.business = business.map((company: any) => {
      return {
        name: `${company.nombre} - ${company.rut}`,
        value: company.id
      };
    });

    if (business != null && business.length > 0) {
      this.$data.companySelected = business[0].id;

      this.$emit("businessLoaded", business[0].id);
    } else {
      this.$data.business = [
        {
          id: 1,
          name: "Sin empresas"
        }
      ];
      this.$data.companySelected = 1;

      this.$emit("businessLoaded", 1);
    }
  }
})
export default class BusinessSelect extends Vue {
  @Prop() value!: number;
}
