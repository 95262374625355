




































import { Component, Vue, Prop } from "vue-property-decorator";
import { RedCapital, RedCapitalConfig, SelectedCountry } from "@/configuration";
import { isRutValid, rutParser } from "@/utils";

Vue.directive("rut", {
  bind(el, binding) {
    const inputEvents = ["keyup"];

    let formatter = (e: any) => {
      let value = e.target.value;
      e.target.value = rutParser(value);
    };
    inputEvents.forEach(event => {
      el.addEventListener(event, formatter);
    });
  }
});

@Component({
  props: {
    isDashed : Boolean
  },
  data() {
    return {
      SelectedCountry,
      RedCapital,
      dni: "",
      isFocus: false,
      rules: {
        ruc: [
          (v: any) =>
            !!v || `Número de documento es requerido`,
          (v: any) =>
            (v.length <=15) ||
            `Número de documento no válido`
        ],
        rut: [
          (v: any) =>
            !!v || `${RedCapitalConfig(SelectedCountry).DniName} es requerido`,
          (v: any) =>
            isRutValid(v) ||
            `${RedCapitalConfig(SelectedCountry).DniName} inválido`,
          (v: any) => v.length < 13 || "Maximo 12 caracteres",
        ]
      }
    };
  },
  watch: {
    dni() {
      // @ts-ignore
      this.onChange();
      
    },
    value(val) {
      if (SelectedCountry == "CL" || SelectedCountry == "PYME") {
        this.$data.dni = rutParser(val);
      } else {
        this.$data.dni = val;
      }
    }
  },
  created() {
    this.$data.dni = this.$props.value;
    //console.log(this.$props.isLogin)
  },
  methods: {
    onChange() {
      this.$emit("input", this.$data.dni);
    }
  }
})
export default class DniField extends Vue {
  @Prop({ default: false }) disabled!: boolean;
  @Prop() value!: string;
  @Prop() label!: string;
  @Prop() prefix!: string;
  @Prop() suffix!: string;
  //Variable encargada de modificar la prop. "single-line"
  @Prop({default : false}) isLogin!: boolean; 
}
