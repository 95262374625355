import { SelectedCountry, RedCapitalConfig } from "@/configuration";
import moment from "moment";

export interface ProjectType {
  id: number;
  valor: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
}

export interface ProjectText {
  id: number;
  valor: string;
}

export interface ProjectAmount {
  proyecto_id: number;
  monto: string;
  reserva: string;
}

export interface ProjectInterface {
  id: number;
  id_antiguo: number;
  nombre: string;
  verificado_id: number;
  tir: number;
  tasa: number;
  cuotas: number;
  comision_solicitante: number;
  comision_uso_p: number;
  credito_tipo: ProjectText;
  credito_tipo_id: number;
  dealer_id: number;
  desc_derechos: number;
  desc_uso: number;
  desc_uso_plataforma_sol: number;
  duracion: 30;
  empresa_id: number;
  facturado: number;
  fecha_entrega_dinero: Date;
  fecha_estimada_pago: Date;
  fecha_facturacion: Date;
  fianza: any;
  fianza_id: number;
  gastos_notariales: number;
  get_cant_inversionista_count: number;
  get_monto_proyecto: ProjectAmount;
  get_monto_proyecto_inversion_tmp: ProjectAmount;
  get_monto_reserva: ProjectAmount;
  imagen_id: number;
  marca: number;
  moneda_id: number;
  monto: number;
  monto_entregado: number;
  monto_final_adelantado: number;
  monto_minimo: number;
  monto_disponible: number;
  motivo: string;
  multiplicador_derechos: number;
  multiplicador_uso: number;
  notaria_extra: number;
  pagado_dealer: boolean;
  porcent_dealer: number;
  privacidad_id: number;
  proyecto_tipo: ProjectType;
  proyecto_tipo_id: number;
  resumen: string;
  transferido: boolean;
  updated_at: Date;
}

export class Project implements ProjectInterface {
  id!: number;
  id_antiguo!: number;
  nombre!: string;
  verificado_id!: number;
  tir!: number;
  tasa!: number;
  cuotas!: number;
  comision_solicitante!: number;
  comision_uso_p!: number;
  credito_tipo!: ProjectText;
  credito_tipo_id!: number;
  dealer_id!: number;
  desc_derechos!: number;
  desc_uso!: number;
  desc_uso_plataforma_sol!: number;
  duracion!: 30;
  empresa_id!: number;
  facturado!: number;
  fecha_entrega_dinero!: Date;
  fecha_estimada_pago!: Date;
  fecha_facturacion!: Date;
  fianza: any;
  fianza_id!: number;
  gastos_notariales!: number;
  get_cant_inversionista_count!: number;
  get_monto_proyecto!: ProjectAmount;
  get_monto_proyecto_inversion_tmp!: ProjectAmount;
  get_monto_reserva!: ProjectAmount;
  imagen_id!: number;
  marca!: number;
  moneda_id!: number;
  monto!: number;
  monto_entregado!: number;
  monto_final_adelantado!: number;
  monto_minimo!: number;
  monto_disponible!: number;
  motivo!: string;
  multiplicador_derechos!: number;
  multiplicador_uso!: number;
  notaria_extra!: number;
  pagado_dealer!: boolean;
  porcent_dealer!: number;
  privacidad_id!: number;
  proyecto_tipo!: ProjectType;
  proyecto_tipo_id!: number;
  resumen!: string;
  transferido!: boolean;
  updated_at!: Date;
  constructor(project: any) {
    for (let key in project) {
      // @ts-ignore
      this[key] = project[key];
    }
  }
  minAmountToInvest() {
    return this.monto_disponible <
      RedCapitalConfig(SelectedCountry).Project.MinInvest
      ? this.monto_disponible
      : RedCapitalConfig(SelectedCountry).Project.MinInvest;
  }
  maxAmountToInvest() {
    return this.monto_disponible;
  }
  currentAmountInvested() {
    if (this.get_monto_proyecto != null) {
      return parseInt(this.get_monto_proyecto.monto);
    } else {
      return 0;
    }
  }
  currentAmountInvestedPercentage() {
    return (this.currentAmountInvested() * 100) / this.monto;
  }
  currentAmountReserved() {
    if (this.get_monto_reserva != null) {
      return parseInt(this.get_monto_reserva.reserva);
    } else {
      return 0;
    }
  }
  currentAmountReservedPercentage() {
    return (this.currentAmountReserved() * 100) / this.monto;
  }
  currentAmountOnTemporals() {
    if (this.get_monto_proyecto_inversion_tmp != null) {
      return parseInt(this.get_monto_proyecto_inversion_tmp.monto);
    } else {
      return 0;
    }
  }
  currentAmountOnTemporalsPercentage() {
    return (this.currentAmountOnTemporals() * 100) / this.monto;
  }
  currentAmountTotal() {
    if (this.monto_disponible != undefined) {
      return this.monto - this.monto_disponible;
    } else {
      return this.monto;
    }
  }
  currentAmountTotalPercentage() {
    return (this.currentAmountTotal() * 100) / this.monto;
  }
  timeLimitText() {
    let inicio = moment(this.fecha_estimada_pago);
    let hoy = moment(moment(Date.now()).format("YYYY-MM-DD"));

    let difference = inicio.diff(hoy, "day");

    if (this.credito_tipo.id == 1) {
      if (inicio <= hoy) {
        return this.cuotas == 1 ? `${this.cuotas} Dia` : `${this.cuotas} Días`;
      } else {
        return Math.round(difference) == 1
          ? `${Math.round(difference)} Dia`
          : `${Math.round(difference)} Días`;
      }
    } else {
      if (inicio <= hoy) {
        return this.cuotas == 1 ? `${this.cuotas} Mes` : `${this.cuotas} Meses`;
      } else {
        return Math.ceil(difference / 300) == 1
          ? `${Math.ceil(difference / 30)} Mes`
          : `${Math.ceil(difference / 30)} Meses`;
      }
    }
  }
  amountAvailablePercentage() {
    return (this.monto_disponible * 100) / this.monto;
  }
  subtractToCurrentAmount(amount: number) {
    if (this.monto_disponible != undefined) {
      this.monto_disponible += amount;
    }
  }
  updateAmounts(
    amountInvested: ProjectAmount,
    amountReserved: ProjectAmount,
    amountOnTemporal: ProjectAmount,
    amountAvailable?: number
  ) {
    this.get_monto_proyecto = amountInvested;
    this.get_monto_proyecto_inversion_tmp = amountReserved;
    this.get_monto_reserva = amountOnTemporal;
    if (amountAvailable != undefined) {
      this.monto_disponible = amountAvailable;
    }
  }
  progressBarValues(reduced: boolean, amountTemporal?: number) {
    if (reduced) {
      return [
        {
          value: this.currentAmountTotalPercentage(),
          color: "success",
          text: "Financiado un ",
          fixed: 1
        }
      ];
    } else {
      return [
        {
          value: this.currentAmountInvestedPercentage(),
          color: "success",
          text: "Financiado un ",
          fixed: 1
        },
        {
          value:
            this.currentAmountReservedPercentage() +
            this.currentAmountOnTemporalsPercentage(),
          color: "primary",
          text: "Reservado un ",
          fixed: 1
        }
      ];
    }
  }
}
