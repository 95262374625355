import Vue from "vue";
import Vuetify from "vuetify";
import "@/assets/mainassets.scss";
import App from "@/App.vue";
import {
  Configuration,
  RedCapitalConfig,
  SelectedCountry,
  Backend,
  ShowDebug
} from "@/configuration";
import router from "@/router";
import store from "@/store";
// @ts-ignore
import VueCarousel from "vue-carousel";
// @ts-ignore
import VueNumeric from "vue-numeric";
// @ts-ignore
import VueMeta from "vue-meta";
// @ts-ignore
import VueMultianalytics from "vue-multianalytics";
import VueLazyload from "vue-lazyload";
// @ts-ignore
import VueMoment from "vue-moment";
import DatatableFilterPlugin from "@/filters";
import SocketIO from "socket.io-client";

Vue.config.productionTip = false;

declare module "vue/types/vue" {
  interface Vue {
    $DatatableFilter: any;
  }
}

declare global {
  interface Window {
    io: any;
    grecaptcha: any;
  }
}

let gaConfig = {
  appName: `${Configuration.Analytics.Name}`,
  appVersion: "0.97",
  trackingId: `${Configuration.Analytics.UA}`,
  globalDimensions: [],
  globalMetrics: []
};

window.io = SocketIO;

Vue.use(VueCarousel);
Vue.use(VueMultianalytics, {
  modules: {
    ga: gaConfig
  },
  routing: {
    vueRouter: router
  }
});
Vue.use(VueNumeric);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});
Vue.use(VueLazyload);
Vue.use(DatatableFilterPlugin);
Vue.use(VueMoment);

Vue.use(Vuetify, {
  theme: {
    white: "#ffffff",
    lightestgrey: "#F5F5F5",
    lightgrey: "#e8e8e8",
    darkgrey: "#c2c2c2",
    dimgrey: "#696969",
    primary: "#ff8a00",
    secondary: "#951836",
    rclaro: "#F57828",
    simul: "#ACACAC",
    invest: "#DC742D",
    club: "#C79125",
    total: "#444444",
    mont: "#049E80",
    rent: "#11ADD2",
    pend: "#EF442F",
    btn_home:"#F47828",
    barlogin: "#EFEFEF",
    btnsitios: "#C3570F",
    btnsitios2: "#FD773F",
    onboarding:"#FD773F",
    lightorange:"#F7931E",
    id_proyect:"#F88C7E",
    barcolor:"#00D1A8",
    //financiado:"#40c773"
  }
});

Vue.filter('currency',(value: any, prefix:any) => {

  let strValue = value.toString();
  if (value.toString().length == 4) {
    return `${prefix}${
      strValue[0]
    }.${strValue
      .split("")
      .slice(1, 4)
      .join("")}${RedCapitalConfig(SelectedCountry).Currency.Suffix}`;
  } else {
    return `${prefix}${value.toLocaleString(
      RedCapitalConfig(SelectedCountry).Currency.LocaleString
    )}${RedCapitalConfig(SelectedCountry).Currency.Suffix}`;
  }
});
Vue.filter('pecurrency',(value: any, prefix:any) => {

  let strValue = value.toString();
  if (value.toString().length == 4) {
    return `S/{prefix}${
      strValue[0]
    }.${strValue
      .split("")
      .slice(1, 4)
      .join("")}${RedCapitalConfig(SelectedCountry).Currency.Suffix}`;
  } else {
    return `S/{prefix}${value.toLocaleString(
      RedCapitalConfig(SelectedCountry).Currency.LocaleString
    )}${RedCapitalConfig(SelectedCountry).Currency.Suffix}`;
  }
});

Vue.mixin({
  data() {
    return {
      SelectedCountry,
      RedCapital: RedCapitalConfig(SelectedCountry),
      Backend,
      ShowDebug
    };
  }
});

export const EventBus = new Vue();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
