




















































import { Component, Vue } from "vue-property-decorator";
import {
  TitleTemplateDashboard,
  SelectedCountry,
  RedCapital,
} from "@/configuration";
import { mapState } from "vuex";
// @ts-ignore
import PerfildeUsuario from "@/views/misDatos/PerfildeUsuario.vue";
import MyPassword from "@/views/misDatos/MyPassword.vue"
import CuentasBancarias from "@/views/misDatos/Cuentasbancarias.vue"
import API from "@/api";
import { EventBus } from "@/main";
import Axios from "axios";
// @ts-ignore
import { Tabs, TabItem } from 'vue-material-tabs';
import ShowLoading from "@/components/panel/ShowLoading.vue";

@Component({
  metaInfo: {
    title: "Mis datos",
    titleTemplate: TitleTemplateDashboard,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    },
  },
  computed: {
    ...mapState(["user","setOpenMenuPanel"]),  
    setOpenMenuPanel(){
      return this.$store.state.openMenuPanel;
    },
    
  },
  data() {
    return {
      tabNav: 0,
      isReady: true,
      canShow: false,
      text1:"before",
      text2:"mount",
      text3:"update",
    };
  },
  beforeMount() {    
    
      // @ts-ignore
      this.cargar();
    
    
  },
  methods: {
    cargar() {
      EventBus.$on("mostrarDatosPerfil",  (mostrar: Boolean) => {            
        setTimeout(() => {
          this.$data.canShow = mostrar;
        }, 1000);
      
      console.log(this.$data.canShow)
      });
    }
  },
  components: {
    Tabs,
    TabItem,
    PerfildeUsuario,
    ShowLoading,
    MyPassword,
    CuentasBancarias,
  },
})
export default class Myinfo extends Vue {}
