import Investments from '@/views/dashboard/Investments.vue'
import PendingPayments from '@/views/dashboard/PendingPayments.vue'

export default [
  {
    path: 'mis-inversiones',
    name: 'my-investments',
    component: Investments,
  },
  {
    path: 'transferencias-pendientes',
    name: 'pending-payments',
    component: PendingPayments,
	},
  {
    path: 'estado-pagos',
    name: 'payment-status',
    component: () => import(/* webpackChunkName: "payment-status" */ '@/views/dashboard/PaymentStatus.vue'),
  },
  {
    path: 'detalle-pagos',
    name: 'payment-details',
    component: () => import(/* webpackChunkName: "payment-details" */ '@/views/dashboard/PaymentDetails.vue'),
  },
	//Profile stuffs
  {
    path: 'cuenta-bancaria',
    name: 'bank-account',
    component: () => import(/* webpackChunkName: "bank-account" */ '@/views/dashboard/BankAccount.vue'),
  },
  {
    path: 'pagar-socio-club',
    name: 'pay-membership',
    component: () => import(/* webpackChunkName: "pay-membership" */ '@/views/dashboard/PayMembership.vue'),
  },
  {
    path: 'pago-pendiente/:paymentId',
    name: 'pay-pending-payment',
    component: () => import(/* webpackChunkName: "pay-pending-payment" */ '@/views/dashboard/PayPendingPayment.vue'),
    props: true,
  },
]