<template>
	<div class="public-navbar">
		<v-toolbar v-resize="onResize" dark fixed app clipped-left
			:color="dark ? 'active-menu' : 'black'"	
			:class="
				user != null && showPanelInv != false
				? { [`pt-${isMobile ? 0 : 0}`]: true }
				: { [`pt-${isMobile ? 0 : 0}`]: true }
		">
			<!-- Menu Movil -->
			<v-toolbar-side-icon v-if="isMobile" @click="showMenu = true" alt="Mostrar menu" aria-label="Menu"></v-toolbar-side-icon>
			<router-link to="/" style="margin: auto;padding-right: 36px;">
				<img class="rclogo" alt="RedCapital logo" :src="`${RedCapital[SelectedCountry].Logo}`"/>
			</router-link>
			
			<v-toolbar-items v-if="!isMobile" style="width:100%">
				<!-- /como-funciona-solicitar -->
				<v-btn class="font-OneRem" style="text-transform:capitalize" to="/solicitante" flat>
					Financiarte
				</v-btn>
				<!-- Menu:  Como Funciona -->
				<v-menu v-if="SelectedCountry == 'PE'" offset-y open-on-hover>
					<template v-slot:activator="{ on }">
						<v-btn class="ml-0 nav-1000 hidden-sm-and-down font-OneRem" style="text-transform:capitalize" flat v-on="on">
							Cómo funciona
							<v-icon>arrow_drop_down</v-icon>
						</v-btn>
					</template>
					<v-list>
						<!-- :to="{ name: 'comofuncionainvertir' }" -->
						<v-list-tile  to="/como-funciona" flat v-if="SelectedCountry == 'PE'">
							<v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" style="text-transform:capitalize" flat>
								Inversiones
							</v-list-tile-title>
						</v-list-tile>
						<v-list-tile  to="/solicitante">
							<v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" style="text-transform:capitalize" flat>
								Financiamiento
							</v-list-tile-title>
						</v-list-tile>
					</v-list>
				</v-menu>
				<!-- Tarjeta prepago -->
				<v-btn class="font-OneRem" style="text-transform:capitalize" to="/tarjetaprepago" flat>
					Tarjeta prepago
				</v-btn>
				<!-- Menu:  Nosotros -->
				<v-menu v-if="SelectedCountry == 'PE'" offset-y open-on-hover>
					<template v-slot:activator="{ on }">
						<v-btn class="ml-0 nav-1000 hidden-sm-and-down font-OneRem" style="text-transform:capitalize" flat v-on="on">
							Nosotros
							<v-icon>arrow_drop_down</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-tile  to="/quienes-somos">
							<v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" style="text-transform:capitalize" flat>
								¿Quiénes somos?
							</v-list-tile-title>
						</v-list-tile>
						<v-list-tile  to="/prensa">
							<v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" style="text-transform:capitalize" flat>
								En Prensa
							</v-list-tile-title>
						</v-list-tile>
					</v-list>
				</v-menu>
				<!-- Menu:  Ayuda -->
				<v-menu v-if="SelectedCountry == 'PE'" offset-y open-on-hover>
					<template v-slot:activator="{ on }">
						<v-btn class="ml-0 nav-1000 hidden-sm-and-down font-OneRem" style="text-transform:capitalize" flat v-on="on">
							Ayuda
							<v-icon>arrow_drop_down</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-tile  to="/preguntas-frecuentes">
							<v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" flat style="text-transform:capitalize">
								Preguntas Frecuentes
							</v-list-tile-title>
						</v-list-tile>
						<v-list-tile to="/garantia-riesgos">
							<v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" flat>
								Respaldos y Riesgos
							</v-list-tile-title>
						</v-list-tile>
						<v-list-tile @click="gohablemos()">
							<v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" flat style="text-transform:capitalize">
								contacto
							</v-list-tile-title>
						</v-list-tile>
						<v-list-tile  to="/documentos-descargables">
							<v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" flat style="text-transform:capitalize">
								Documentos
							</v-list-tile-title>
						</v-list-tile>
					</v-list>
				</v-menu>
				<!-- Spacer -->
				<v-spacer></v-spacer>
				<!-- <v-menu offset-y v-if="user == null"> -->
				<!-- <v-btn v-if="user != null && user.user_tipo_id == 1" to="/admin/asociar" flat>Controlar cuenta</v-btn> -->
				<v-btn v-if="user == null" class="ml-2" style="text-transform:capitalize;height: auto;padding: 5px 17px;font-size:1rem !important" outline round color="white" dark v-on="on" to="/registrar">
					Registrarse 
				</v-btn>
				<!-- @click="login" -->
				<v-btn v-if="user == null" class="ml-2" style="text-transform:capitalize;height: auto;padding: 5px 17px;background: #ec6b1c !important;font-size:1rem !important" outline round color="white" dark v-on="on" to="/login">
					Ingresar
				</v-btn>
				<!-- Usuario Logeado -->
				<v-menu offset-y v-if="user != null">
					<template v-slot:activator="{ on }">
						<v-btn class="ml-0 nav-1000 hidden-sm-and-down" v-if="user != null" flat v-on="on">
							{{ user.nombre }} {{ user.app_pat }}
							<v-icon>arrow_drop_down</v-icon>
						</v-btn>
					</template>
					<v-list>
							<v-list-tile v-if="(menu == 2 || user.user_tipo_id != 2) && $route.path != '/evaluacion' && user.via_id != 2 " to="/invertir">
							<v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" flat>Invertir</v-list-tile-title>
							</v-list-tile>
							<v-list-tile v-if="menu == 2 || user.user_tipo_id != 3"
							@click="panelInversionist()"
							
							>
							
							<v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" flat
								>Panel Inversionista</v-list-tile-title
							>
							</v-list-tile>
							<v-list-tile v-if="menu == 3 && user.user_tipo_id != 2"
							@click="panelFacture()" >
							<v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" flat
								>Panel Solicitante</v-list-tile-title
							>
							</v-list-tile>
							<v-list-tile v-if="menu == 2 || user.user_tipo_id != 3"
							to="/panel/inversionista/mis-inversiones"
							>
							<v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" flat
								>Resumen inversiones</v-list-tile-title
							>
							</v-list-tile>
							<v-list-tile v-if="menu == 2 || user.user_tipo_id != 3"
							to="/panel/inversionista/transferencias-pendientes"
							>
							<v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" flat
								>Transferencias pendientes</v-list-tile-title
							>
							</v-list-tile>
							<v-list-tile @click="logout">
							<v-list-tile-title class="ml-0 nav-1000 hidden-sm-and-down" flat
								>Cerrar sesión</v-list-tile-title
							>
							</v-list-tile>
						</v-list>
					</v-menu>


				<!-- Banderas -->
				<v-menu offset-y open-on-hover>
					<template v-slot:activator="{ on }">
						<v-btn v-if="SelectedCountry == 'PE'" class="ml-0 nav-1000 hidden-sm-and-down font-OneRem" style="text-transform:capitalize" flat v-on="on">
							<v-img src="/images/flag_peru.svg" aspect-ratio="1.7" contain style="height:26px"></v-img>
						</v-btn>
					</template>
					<v-list>
						<v-list-tile  href="https://www.redcapital.cl/">
							<v-list-tile-avatar :tile="true">
								<v-img src="/images/flag_chile.svg" aspect-ratio="1.7" contain style="height:26px"></v-img>
							</v-list-tile-avatar>
							<v-list-tile-content style="text-transform:capitalize">
								<v-list-tile-title>Chile</v-list-tile-title>
							</v-list-tile-content>
						</v-list-tile>
						<v-list-tile  href="https://www.redcapital.com.co/">
							<v-list-tile-avatar :tile="true">
								<v-img src="/images/flag_colombia.svg" aspect-ratio="1.7" contain style="height:26px"></v-img>
							</v-list-tile-avatar>
							<v-list-tile-content style="text-transform:capitalize">
								<v-list-tile-title>Colombia</v-list-tile-title>
							</v-list-tile-content>
						</v-list-tile>
					</v-list>
				</v-menu>
			</v-toolbar-items>
		</v-toolbar>
		<v-navigation-drawer v-model="showMenu" left app fixed temporary class="rc-menuDrawerSitios">
			<v-list class="transparent pa-2">
				<!-- Pais -->
				<v-list-group class="rc-list-itemNav">
					<template v-slot:activator>
						<v-list-tile v-if="SelectedCountry == 'PE'" class="rc-listItem-flag">
							<v-list-tile-avatar :tile="true">
								<v-img src="/images/flag_peru.svg" aspect-ratio="1.7" contain style="height:26px"></v-img>
							</v-list-tile-avatar>
							<v-list-tile-content>
								<v-list-tile-title class="rc-listItem-title">Perú</v-list-tile-title>
							</v-list-tile-content>
						</v-list-tile>
					</template>
					<v-list-tile href="https://redcapital.cl/">
						<v-list-tile-avatar :tile="true">
							<v-img src="/images/flag_chile.svg" aspect-ratio="1.7" contain style="height:26px"></v-img>
						</v-list-tile-avatar>
						<v-list-tile-content>
							<v-list-tile-title class="rc-listItem-title">Chile</v-list-tile-title>
						</v-list-tile-content>
					</v-list-tile>
				<v-list-tile href="https://redcapital.cl/">
					<v-list-tile-avatar :tile="true">
						<v-img src="/images/flag_chile.svg" aspect-ratio="1.7" contain style="height:26px"></v-img>
					</v-list-tile-avatar>
					<v-list-tile-content>
						<v-list-tile-title class="rc-listItem-title">Chile</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>
				<v-list-tile href="https://www.redcapital.com.co/">
					<v-list-tile-avatar :tile="true">
						<v-img src="/images/flag_colombia.svg" aspect-ratio="1.7" contain style="height:26px"></v-img>
					</v-list-tile-avatar>
					<v-list-tile-content>
						<v-list-tile-title class="rc-listItem-title">Colombia</v-list-tile-title>
					</v-list-tile-content>
				</v-list-tile>
			</v-list-group>
			<!-- Pais -->
			<!-- Financiarte -->
			<v-list-tile class="v-list-item rc-list-itemNav rc-listItem-pad" v-if="user == null" to="/solicitante">
				<div style="text-align: center; width: 100%; line-height: 1;">
					<div class="rc-listItem-title">Financiarte</div>
				</div>
			</v-list-tile>
			<!-- Financiarte -->
			<!-- Tarjeta prepago -->
			<v-list-tile class="v-list-item rc-list-itemNav rc-listItem-pad" v-if="user == null" to="/tarjetaprepago">
				<div style="text-align: center; width: 100%; line-height: 1;">
					<div class="rc-listItem-title">Tarjeta prepago</div>
				</div>
			</v-list-tile>
			<!-- Tarjeta prepago -->
			<!-- Como Funciona -->
			<v-list-group class="rc-list-itemNav">
				<template v-slot:activator>
					<div style="text-align: center; width: 100%; line-height: 1;">
						<div class="rc-listItem-title">Cómo funciona</div>
					</div>
				</template>
				<v-list-tile to="/como-funciona">
					<div style="text-align: center; width: 100%; line-height: 1;">
						<div class="rc-listItem-title">Inversiones</div>
					</div>
				</v-list-tile>
				<v-list-tile to="/solicitante">
					<div style="text-align: center; width: 100%; line-height: 1;">
						<div class="rc-listItem-title">Financiamiento</div>
					</div>
				</v-list-tile>
			</v-list-group>
			<!-- Como Funciona -->
			<!-- Nosotros -->
			<v-list-group class="rc-list-itemNav">
				<template v-slot:activator>
					<div style="text-align: center; width: 100%; line-height: 1;">
						<div class="rc-listItem-title">Nosotros</div>
					</div>
				</template>
				<v-list-tile to="/quienes-somos">
					<div style="text-align: center; width: 100%; line-height: 1;">
						<div class="rc-listItem-title">¿Quiénes somos?</div>
					</div>
				</v-list-tile>
				<v-list-tile to="/prensa">
					<div style="text-align: center; width: 100%; line-height: 1;">
						<div class="rc-listItem-title">En Prensa</div>
					</div>
				</v-list-tile>
			</v-list-group>
			<!-- Nosotros -->
			<!-- Ayuda -->
			<v-list-group class="rc-list-itemNav">
				<template v-slot:activator>
					<div style="text-align: center; width: 100%; line-height: 1;">
						<div class="rc-listItem-title">Ayuda</div>
					</div>
				</template>
				<v-list-tile to="/preguntas-frecuentes">
					<div style="text-align: center; width: 100%; line-height: 1;">
						<div class="rc-listItem-title">Preguntas Frecuentes</div>
					</div>
				</v-list-tile>
				<v-list-tile to="/garantias-riesgos">
					<div style="text-align: center; width: 100%; line-height: 1;">
						<div class="rc-listItem-title">Respaldos y Riesgos</div>
					</div>
				</v-list-tile>
				<v-list-tile @click="gohablemos()">
					<div style="text-align: center; width: 100%; line-height: 1;">
						<div class="rc-listItem-title">Contacto</div>
					</div>
				</v-list-tile>
				<v-list-tile to="/documentos-descargables">
					<div style="text-align: center; width: 100%; line-height: 1;">
						<div class="rc-listItem-title">Documentos</div>
					</div>
				</v-list-tile>
			</v-list-group>
			<!-- Ayuda -->
			<!-- No logeado -->
			<v-list-tile class="v-list-item rc-list-itemNav rc-listItem-pad" v-if="user == null" to="/registrar">
				<div style="text-align: center; width: 100%; line-height: 1;">
					<div class="rc-listItem-title">Crear cuenta</div>
				</div>
			</v-list-tile>
			<v-list-tile class="v-list-item rc-list-itemNav rc-listItem-pad" v-if="user == null" @click="login">
				<div style="text-align: center; width: 100%; line-height: 1;">
					<div class="rc-listItem-title">Ingresar</div>
				</div>
			</v-list-tile>
			<!-- No logeado -->
			<!-- Logeado -->
			<v-list-group class="rc-list-itemNav rc-listNameUser" v-if="user != null">
				<template v-slot:activator>
					<div style="text-align: center; width: 100%; line-height: 1;">
						<span class="rc-listItem-img material-icons" style="height:26px;width:26px">account_circle</span>
						<div class="rc-listItem-title">{{ nombreCompleto }}</div>
					</div>
				</template>
				<v-list-tile 
					v-for="(redCapitalInversionistaMenu, index) in redCapitalInversionistaMenus" 
					:key="`${redCapitalInversionistaMenu.icon}-${index}`" 
					class="v-list-item rc-list-item rc-listItem-pad" 
					:active-class="activeClassMenu" 
					:to="redCapitalInversionistaMenu.to.path ? redCapitalInversionistaMenu.to.path : {name: redCapitalInversionistaMenu.to.name}">
					<div style="text-align: center; width: 100%; line-height: 1;">
						<span class="rc-listItem-img material-icons-outlined" style="height:26px;width:26px">{{ redCapitalInversionistaMenu.icon }}</span>
						<div class="rc-listItem-title">{{ redCapitalInversionistaMenu.text }}</div>
					</div>
				</v-list-tile>
				<v-list-tile class="v-list-item rc-listItem-pad rc-listCerrarSesion" :active-class="activeClassMenu" @click="logout">
					<div style="text-align: center; width: 100%; line-height: 1;">
						<v-icon class="rc-listItem-img">logout</v-icon>
						<div class="rc-listItem-title">Cerrar sesión</div>
					</div>
				</v-list-tile>
			</v-list-group>
			<!-- Logeado -->
		</v-list>
		
			<!-- Logeado -->
			<!-- <v-list class="transparent" v-if="user != null">
				<v-list-group prepend-icon="account_circle">
					<template v-slot:activator>
						<v-list-tile>
							<v-list-tile-title>{{ user.nombre }} {{ user.app_pat }}</v-list-tile-title>
						</v-list-tile>
					</template>
					<v-list-tile :active-class="activeClass" :to="{ name: 'dashboard-stats' }" class="ml-2 v-list-item">
						<v-list-tile-action>
							<i class="material-icons">assignment</i>
						</v-list-tile-action>
						<v-list-tile-title>Mi panel</v-list-tile-title>
					</v-list-tile>
					<v-list-tile class="ml-2" @click="logout">
						<v-list-tile-action>
							<i class="material-icons">exit_to_app</i>
						</v-list-tile-action>
						<v-list-tile-title>Cerrar sesión</v-list-tile-title>
					</v-list-tile>
				</v-list-group>
				<!-- Pais -->
				<!-- Financiarte -->
				<v-list-tile class="v-list-item rc-list-itemNav rc-listItem-pad" v-if="user == null" to="/solicitante">
					<div style="text-align: center; width: 100%; line-height: 1;">
						<div class="rc-listItem-title">Financiarte</div>
					</div>
				</v-list-tile>
				<!-- Financiarte -->
				<!-- Como Funciona -->
				<v-list-group class="rc-list-itemNav">
					<template v-slot:activator>
						<div style="text-align: center; width: 100%; line-height: 1;">
							<div class="rc-listItem-title">Cómo funciona</div>
						</div>
					</template>
					<v-list-tile to="/como-funciona">
						<div style="text-align: center; width: 100%; line-height: 1;">
							<div class="rc-listItem-title">Inversiones</div>
						</div>
					</v-list-tile>
					<v-list-tile to="/solicitante">
						<div style="text-align: center; width: 100%; line-height: 1;">
							<div class="rc-listItem-title">Financiamiento</div>
						</div>
					</v-list-tile>
				</v-list-group>
				<!-- Como Funciona -->
				<!-- Nosotros -->
				<v-list-group class="rc-list-itemNav">
					<template v-slot:activator>
						<div style="text-align: center; width: 100%; line-height: 1;">
							<div class="rc-listItem-title">Nosotros</div>
						</div>
					</template>
					<v-list-tile to="/quienes-somos">
						<div style="text-align: center; width: 100%; line-height: 1;">
							<div class="rc-listItem-title">¿Quiénes somos?</div>
						</div>
					</v-list-tile>
					<v-list-tile to="/prensa">
						<div style="text-align: center; width: 100%; line-height: 1;">
							<div class="rc-listItem-title">En Prensa</div>
						</div>
					</v-list-tile>
				</v-list-group>
				<!-- Nosotros -->
				<!-- Ayuda -->
				<v-list-group class="rc-list-itemNav">
					<template v-slot:activator>
						<div style="text-align: center; width: 100%; line-height: 1;">
							<div class="rc-listItem-title">Ayuda</div>
						</div>
					</template>
					<v-list-tile to="/preguntas-frecuentes">
						<div style="text-align: center; width: 100%; line-height: 1;">
							<div class="rc-listItem-title">Preguntas Frecuentes</div>
						</div>
					</v-list-tile>
					<v-list-tile to="/garantias-riesgos">
						<div style="text-align: center; width: 100%; line-height: 1;">
							<div class="rc-listItem-title">Respaldos y Riesgos</div>
						</div>
					</v-list-tile>
					<v-list-tile @click="gohablemos()">
						<div style="text-align: center; width: 100%; line-height: 1;">
							<div class="rc-listItem-title">Contacto</div>
						</div>
					</v-list-tile>
					<v-list-tile to="/documentos-descargables">
						<div style="text-align: center; width: 100%; line-height: 1;">
							<div class="rc-listItem-title">Documentos</div>
						</div>
					</v-list-tile>
				</v-list-group>
				<!-- Ayuda -->
				<!-- No logeado -->
				<v-list-tile class="v-list-item rc-list-itemNav rc-listItem-pad" v-if="user == null" to="/registrar">
					<div style="text-align: center; width: 100%; line-height: 1;">
						<div class="rc-listItem-title">Crear cuenta</div>
					</div>
				</v-list-tile>
				<v-list-tile class="v-list-item rc-list-itemNav rc-listItem-pad" v-if="user == null" @click="login">
					<div style="text-align: center; width: 100%; line-height: 1;">
						<div class="rc-listItem-title">Ingresar</div>
					</div>
				</v-list-tile>
				<!-- No logeado -->
				<!-- Logeado -->
				<v-list-group class="rc-list-itemNav rc-listNameUser" v-if="user != null">
					<template v-slot:activator>
						<div style="text-align: center; width: 100%; line-height: 1;">
							<span class="rc-listItem-img material-icons" style="height:26px;width:26px">account_circle</span>
							<div class="rc-listItem-title">{{ nombreCompleto }}</div>
						</div>
					</template>
					<div v-if="menu == 2 || user.user_tipo_id != 3">
						<v-list-tile v-for="(redCapitalInversionistaMenu, index) in redCapitalInversionistaMenus" :key="`${redCapitalInversionistaMenu.icon}-${index}`" class="v-list-item rc-list-item rc-listItem-pad" :active-class="activeClassMenu" :to="redCapitalInversionistaMenu.to.path ? redCapitalInversionistaMenu.to.path : {name: redCapitalInversionistaMenu.to.name}">
							<div style="text-align: center; width: 100%; line-height: 1;">
								<span class="rc-listItem-img material-icons-outlined" style="height:26px;width:26px">{{ redCapitalInversionistaMenu.icon }}</span>
								<div class="rc-listItem-title">{{ redCapitalInversionistaMenu.text }}</div>
							</div>
						</v-list-tile>
					</div>
					<div v-if="menu == 3">
						<div v-if="user.user_tipo_id != 2">
							<v-list-tile v-for="(redCapitalSolicitanteMenu, index) in redCapitalSolicitanteMenus" :key="`${redCapitalSolicitanteMenu.icon}-${index}`" class="v-list-item rc-list-item rc-listItem-pad" :active-class="activeClassMenu" :to="redCapitalSolicitanteMenu.to.path ? redCapitalSolicitanteMenu.to.path : {name: redCapitalSolicitanteMenu.to.name}">
							<div style="text-align: center; width: 100%; line-height: 1;">
								<span class="rc-listItem-img material-icons-outlined" style="height:26px;width:26px">{{ redCapitalSolicitanteMenu.icon }}</span>
								<div class="rc-listItem-title">{{ redCapitalSolicitanteMenu.text }}</div>
							</div>
							</v-list-tile>
						</div>
					</div>
					<v-list-tile class="v-list-item rc-listItem-pad rc-listCerrarSesion" :active-class="activeClassMenu" @click="logout">
						<div style="text-align: center; width: 100%; line-height: 1;">
							<v-icon class="rc-listItem-img">logout</v-icon>
							<div class="rc-listItem-title">Cerrar sesión</div>
						</div>
					</v-list-tile>
				</v-list-group>
				<!-- Logeado -->
			</v-list>
		</v-navigation-drawer>
	</div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import {
  Menus,
  Profile,
  MyBusiness,
  MyDocuments,
  redCapitalInversionistaMenus,
  redCapitalSolicitanteMenus,
} from "@/models/DashboardLinks";
import { EventBus } from "@/main";
import { RedCapital, RedCapitalConfig, SelectedCountry } from "@/configuration";

@Component({
  computed: {
    ...mapState(["user", "menu", "showPanelInv", "businesSelected"]),
    canShowLinks() {
      return this.$route.path.startsWith("/panel/");
    },
    projectsVisibility() {
      return (
        this.$store.state.user != null ||
        RedCapitalConfig(SelectedCountry).Project.PublicVisibility
      );
    },
    activeClass() {
      return "primary white--text";
    },
    accountType() {
      return this.$store.state.user.user_tipo_id == 2
        ? "investor"
        : "applicant";
    },
	activeClassMenu() {
      return "rc-menuPanelActive";
    },
  },
  data() {
    return {
		SelectedCountry,
		RedCapital,
		nombreCompleto: null,
		Menus,
		Profile,
		MyBusiness,
		MyDocuments,
		redCapitalInversionistaMenus,
		redCapitalSolicitanteMenus,
		dark: true,
		canShowUserMenu: false,
		showMenu: false,
		isMobile: true,
		uglyLinks: [
			{
			to: "/quienes-somos",
			text: "Sobre nosotros"
			},
			{
			to: "/prensa",
			text: "En prensa"
			},
			{
			to: "/contacto",
			text: "Contacto"
			},
			{
			to: "/preguntas-frecuentes",
			text: "Preguntas frecuentes"
			},
			{
			to: "/garantia-riesgos",
			text: "Garantías y riesgos"
			}
		]
    };
  },
  methods: {
	panelInversionist() {
      this.$store.commit("setMenu", 2);
      this.$router.push("/panel/estadisticas");
     
    },
    panelFacture() {
      this.$store.commit("setMenu", 3);
       this.$router.push("/panel-facto/estadisticas");
    },
    onResize() {
      if (window.innerWidth <= 1100) {
        this.$data.isMobile = true;
      } else {
        this.$data.isMobile = false;
        this.$data.showMenu = false;
      }
    },
	login() {
      this.$data.showMenu = false;
      this.$data.canShowUserMenu = false;
      EventBus.$emit("open-login-dialog");
    },
    logout() {
      EventBus.$emit("logout");
    },
	capitalize(texto){ // #Pendiente dejar esta funcion global para llamar donde sea.
      const lower = texto.toLowerCase();
      return texto.charAt(0).toUpperCase()+lower.slice(1); 
    },
	gohablemos(){
		EventBus.$emit("goHablemos");
	},
  },
  async beforeMount(){
	if(this.$store.state.user != null){
		this.$data.nombreCompleto = this.capitalize(this.$store.state.user.nombre)+' '+this.capitalize(this.$store.state.user.app_pat);
	}
	await EventBus.$on("goHablemos", () => {
		const url = window.location.pathname;
		if(url === '/'){
			const element = document.getElementById("hablemos");
			//@ts-ignore
			element.scrollIntoView({ behavior: "smooth" });
		}else{
			this.$router.push('/');
			setTimeout(()=>{
				const element = document.getElementById("hablemos");
				//@ts-ignore
				element.scrollIntoView({ behavior: "smooth" });
			},500)
		}
	})
  }
})
export default class NavBar extends Vue {}
</script>

<style lang="scss" scoped>
.navbar {
  z-index: 3;
}
.rclogo {
  @media (max-width: 960px) {
    height: 40px;
  }
  @media (min-width: 960px) {
    height: 50px;
  }
}
.v-menu__content {
  position: fixed !important;
}
// MENU
.rc-listItem-title{
	font-weight: 600;
}
// Breakpoints 
@each $breakpoint in $breakpoints_screen_text{
	@include breakpoint_media_queries($breakpoint){
		@if $breakpoint == "x-large"{
			
		}@else if $breakpoint == "large"{
			
		}@else if $breakpoint == "medium-to-large"{
			.rc-menuDrawerSitios{
				margin-top: 0 !important;
				z-index: 5;
				width: 300px !important;
			}
			.rc-listItem-img{
				float: left;
			}
			.rc-listItem-title{
				float: left;
				line-height: 2;
				margin-left: 10px;
			}
		}@else if $breakpoint == "small-to-medium"{
			.rc-menuDrawerSitios{
				margin-top: 0 !important;
				z-index: 5;
				width: 300px !important;
			}
			.rc-listItem-img{
				float: left;
			}
			.rc-listItem-title{
				float: left;
				line-height: 2;
				margin-left: 10px;
			}
		}@else if $breakpoint == "small"{
			.rc-menuDrawerSitios{
				margin-top: 0 !important;
				z-index: 5;
				width: 300px !important;
			}
			.rc-listItem-img{
				float: left;
			}
			.rc-listItem-title{
				float: left;
				line-height: 2;
				margin-left: 10px;
			}
		}
	}
}
</style>
