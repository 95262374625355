






































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  TitleTemplateDashboard,
  SelectedCountry,
  RedCapital,
} from "@/configuration";
import { mapState } from "vuex";
import API from "@/api";
import { EventBus } from "@/main";
import TableResponsive from "@/components/table/TableResponsive.vue";


@Component({
  metaInfo: {
    title: "Mis datos",
    titleTemplate: TitleTemplateDashboard,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    },
  },
  data() {
    return {
        currentFolder: "",
        staticBusiness:[],
        banks:[],
        accountTypes:[],
        cuentas_cargadas:[],
        searchCuentas: "",
        cuentas:false,
        agregar_cuenta:false,
        editar_cuenta:false,
        dialog:false,
        valid:true,
        uploadFolder: {
					load: false,
					ok: false
		},
        cuenta: {
            banco_id: null,
            tipo_id: null,
            numero: null,
            cci: null,
            moneda_id:null,
            empresa_id: null,
        },
        monedas:[],
        disabledButton: true,
        pagination: {
            sortBy: "name",
        },
        dataTable:{
            headers: [
            {
              text: "Nombre o Razón Social",
              align: "center",
              value: "nombre_empresa",
              sortable: false,
            },
            {
              text: "Banco",
              align: "center",
              value: "banco",
              sortable: false,
            },
            {
              text: "Moneda",
              align: "center",
              value: "moneda_id",
              sortable: false,
            },
            {
              text: "CCI",
              align: "center",
              value: "cci",
              sortable: false,
            },
            {
              text: "NºCuenta",
              align: "center",
              value: "numero",
              sortable: false,
            },
            {
              text: "Tipo Cuenta",
              align: "center",
              value:"tipo_cuenta",
              sortable: false,
            },
            {
              text: "Cta. validada",
              align: "center",
              value:"validado",
              sortable: false,
            },
            {
              text: "Acciones",
              align: "center",
              sortable: false,
            },
          ],
          msjEmpty: 'No existen registros en tu cuenta',
				rowsPerPage: {
					txt: 'Registro por página',
					itm: [10, 25, 50, { text: 'Todos', value: -1 }]
				},
        },
        rules: {
            notEmpty: [(v: any) => !!v || "Ingrese un valor"],
            password: [(v: any) => !!v || "La contraseña es requerida"],
            //@ts-ignore
            noVacio: [v => {
							if(!v || v.trim() === ''){
								return 'Campo requerido'
							}else if(v.trim() === '0'){
								return 'Campo requerido'
							}else{
								return true
							}
						}
					],
        },
        verificar_cuenta: false,
    }
  },
  components:{
      TableResponsive
  },
  async beforeMount(){  
    API.getBusiness()
    .then((res: any) => {
        this.$data.cuenta.empresa_id = this.$store.state.businesSelected.id;
        this.$data.staticBusiness = res;
    })
    .catch((err: any) => {
    
    });
    this.$data.banks = await API.getBanks();
    this.$data.accountTypes = await API.getAccountTypes();
    this.$data.monedas = await API.getMonedas();
    // @ts-ignore
    this.miscuentas();
    
    

 },
  computed:{
      dataFiltered(){
          return this.$data.cuentas_cargadas.filter((i : any) => {
            // Props
            let propsObjTableAll = {
                style: `text-align: center;
                        font-size: 0.7rem;
                        font-weight: 500;
                        color: #8b8b8b`
            }
            // Props 
            let propsObjTableEstado = {
                style: `display: flex;
                        justify-content: center;`
            }
            // Props 
            let propsObjTableDefault = {
                style: `display: flex;
                        justify-content: center;`
            }

            let buttons = [
                {
                    funcion: 'editCountChild',
                    icoFont: 'edit',
                    data: i,
                    tooltip: 'Editar cuenta'
                }
            ];

            if(i.validado == 0){
                buttons.push(
                    {
                        funcion: 'selectedCountChild',
                        icoFont: 'credit_score',
                        data: i,
                        tooltip: 'Validar cuenta'
                    }
                )
            }

            //Formateando DATA
            let objTable = {
                razon_social : {
                    value: i.nombre_empresa,
                    label: 'Nombre',
                    props: propsObjTableAll
				},
                banco : {
                    value: i.banco,
                    label: 'Banco',
                    props: propsObjTableAll
				},
                moneda : {
                    value: i.moneda,
                    label: 'Moneda',
                    props: propsObjTableAll
				},
                cci : {
                    value: i.cci,
                    label: 'CCI',
                    props: propsObjTableAll
				},
                nCuenta : {
                    value: i.numero,
                    label: 'N° cuenta',
                    props: propsObjTableAll
				},
                tipo_cuenta : {
                    value: i.tipo_cuenta,
                    label: 'Tipo de cuenta',
                    props: propsObjTableAll
				},
                validado : {
                    value: i.validado != 0 ? 'Si' : 'No',
                    label: 'Cta. validada',
                    props: propsObjTableAll
				},
                acciones: {
                    value: '',
                    label: 'Acciones',
                    props: propsObjTableEstado,
                    buttons: buttons
				},
                
            }

            i.datosEnTabla = objTable;

          })
      },
  },
  watch: {
        '$data.cuenta': {
            deep:true,
            immediate:true,
            handler() {
                const cuenta = this.$data.cuenta;
                this.$data.disabledButton = (
                    cuenta.banco_id !== null &&
                    cuenta.tipo_id !== null &&
                    cuenta.numero !== null &&
                    cuenta.numero !== '' &&
                    cuenta.cci !== '' &&
                    cuenta.cci !== null &&
                    cuenta.moneda_id !== null &&
                    cuenta.empresa_id !== null
                );
            }
        }
},
  methods: {
    editar_cuentas(cuenta) {
        this.$data.cuenta.banco_id = cuenta.banco_id;
        this.$data.cuenta.tipo_id = cuenta.cuenta_tipo_id;
        this.$data.cuenta.numero = cuenta.numero;
        this.$data.cuenta.empresa_id = cuenta.empresa_id;
        this.$data.cuenta.alias = cuenta.alias;
        this.$data.cuenta.cuenta_id = cuenta.id;
        this.$data.cuenta.moneda_id = cuenta.moneda_id;
        this.$data.cuenta.cci = cuenta.cci;
        this.$data.editar_cuenta = true;
    },
    cuentaSeleccionada(cuenta){
        this.$data.cuenta = cuenta;
        this.$data.cuenta.banco = cuenta.banco;
        this.$data.cuenta.numero = cuenta.numero;
        this.$data.cuenta.cci = cuenta.cci;

        this.$data.verificar_cuenta = true;
    },
    editar() {
        API.factoring
        .actualizar_cuenta_bancaria(this.$data.cuenta)
        .then((res: any) => {
            if(res.status == 200){
                EventBus.$emit("snack-success", "Datos actualizados correctamente");
                this.$data.editar_cuenta = false;
                this.$data.cuenta.banco_id = "";
                this.$data.cuenta.tipo_id = "";
                this.$data.cuenta.numero = "";
                this.$data.cuenta.empresa_id = "";
                this.$data.cuenta.alias = "";
                this.$data.cuenta.moneda_id = "";
                this.$data.cuenta.cci = "";
    
                if (this.$store.state.businesSelected != null) {
                    // @ts-ignore
                    this.miscuentas();
                }
            }
            else if(res.status == 422){
                let mensaje = '';

                Object.values(res.errors).forEach(element => {
                    mensaje += element + '' + ' ';
                });

                this.$data.dialog = false;
                EventBus.$emit("snack-error", mensaje);
            }
        })
        .catch((err: any) => {});
    },
    guardar() {
        this.$data.dialog = true;
        API.factoring
        .guardar_cuenta_banco(this.$data.cuenta)
        .then((res: any) => {
            if(res.status == 200){
                EventBus.$emit("snack-success", "Datos actualizados correctamente");
                this.$data.cuentas = false;
                this.$data.dialog = false;
                this.$data.cuenta.banco_id = "";
                this.$data.cuenta.tipo_id = "";
                this.$data.cuenta.numero = "";
                this.$data.cuenta.empresa_id = "";
                this.$data.cuenta.alias = "";
                this.$data.cuenta.cci = "";
                this.$data.cuenta.moneda_id = "";
                this.$data.cuenta.documento = null;
                if (this.$store.state.businesSelected != null) {
                    // @ts-ignore
                    this.miscuentas();
                }
            }
            else if(res.status == 422){
                let mensaje = '';

                Object.values(res.errors).forEach(element => {
                    mensaje += element + '' + ' ';
                });

                this.$data.dialog = false;
                EventBus.$emit("snack-error", mensaje);
            }
        })
        .catch((err: any) => {
            
        });
    },
    miscuentas(){
        API.factoring
        .get_cuentas(this.$store.state.businesSelected.id)
        .then((res: any) => {
            this.$data.cuentas_cargadas = res.mis_cuentas;
            this.$data.cuentas_cargadas.forEach((el: any) => {
                this.$data.banks.forEach((bank: any) => {
                    el.banco_id == bank.id ? (el.banco = bank.nombre) : "";
                });
                this.$data.accountTypes.forEach((t_a: any) => {
                    el.cuenta_tipo_id == t_a.id ? (el.tipo_cuenta = t_a.nombre) : "";
                });
                this.$data.staticBusiness.forEach((business: any) => {
                    el.empresa_id == business.id
                    ? (el.nombre_empresa = business.nombre)
                    : "";
                });
                this.$data.monedas.forEach((moneda:any) => {
                    el.moneda_id == moneda.id ? (el.moneda = moneda.nombre) : "";
                })
            });
            // @ts-ignore
            this.$store.commit("setCuentasBancarias", this.cuentasBancariasValidas());

            
        })
        .catch((err: any) => {});
    },
    validarCuenta(){
        this.$data.dialog = true;
        API.factoring.validar_cuenta_bancaria(this.$data.cuenta.id).
        then((response) => {
            const res = response;
            if(res.status == 200){
                EventBus.$emit("snack-success", "Cuenta validada correctamente");
                this.$data.verificar_cuenta = false;
                this.$data.dialog = false;
                if (this.$store.state.businesSelected != null) {
                    // @ts-ignore
                    this.miscuentas();
                }
            }
            else{
                let mensaje = '';

                Object.values(response.errors).forEach(element => {
                    mensaje += element + '' + ' ';
                });

                this.$data.dialog = false;
                EventBus.$emit("snack-error", mensaje);
            }
        })
    },
    cuentasBancariasValidas(){
        let validCuentas = this.$data.cuentas_cargadas.every((cuenta:any) =>  cuenta.validado === 1);
        return validCuentas;
    },
    agregarCuenta(){
        this.$data.cuenta.banco_id = "";
        this.$data.cuenta.tipo_id = "";
        this.$data.cuenta.numero = "";
        this.$data.cuenta.empresa_id = "";
        this.$data.cuenta.alias = "";
        this.$data.cuenta.moneda_id = "";
        this.$data.cuenta.cci = "";

        //@ts-ignore
        this.$data.cuenta.empresa_id = this.$store.state.businesSelected.id;

        this.$data.cuentas = true;
    }
  }
})
export default class Cuentasbancarias extends Vue {}
