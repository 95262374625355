









































































































































































































































































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import {
  SelectedCountry,
  RedCapital,
  TitleTemplateDashboard
} from "@/configuration";
import { mapState } from "vuex";
// @ts-ignore
import JsonExcel from "vue-json-excel";
import DatatableFilterPlugin from "@/filters";
import ProjectStatus from "@/components/dashboard/ProjectStatus.vue";
import TableResponsive from "@/components/table/TableResponsive.vue";
import moment from "moment";
import API from "@/api";
import api from "@/api";

Vue.use(DatatableFilterPlugin);
Vue.component("downloadExcel", JsonExcel);

@Component({
  metaInfo: {
    title: "Mis inversiones",
    titleTemplate: TitleTemplateDashboard,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang
    }
  },
  computed: {
    ...mapState(["user", "setOpenMenuPanel"]),
    filteredDatatable() {
      return this.$data.newInvestments.filter((i: any) =>
        Object.keys(this.$data.filters).every(
          f =>
            this.$data.filters[f].length < 0 ||
            this.$data.filters[f].includes(i[f])
        )
      );
    },
    filtered2() {
				//Formatear Data
				return this.$data.newInvestments.filter((i: any) => {
					// Props 
					let propsObjTableAll = {
						style: `text-align: center;
								font-size: 0.7rem;
								font-weight: 500;
								color: #8b8b8b`
					}
					// Props 
					let propsObjTableEstado = {
						style: `display: flex;
								justify-content: center;`
					}
					// #Campo - N° Documento
					let numeroDocumento = null;
					if(i.proyecto.proyecto_factura != null && i.proyecto.proyecto_factura.facturas.length > 0){
						if(i.proyecto.proyecto_factura.facturas.length === 1){
							numeroDocumento = {
								value	: i.proyecto.proyecto_factura.facturas[0].folio,
								label 	: 'N° documento',
								props 	: propsObjTableAll
							}
						}else{
							numeroDocumento = {
								value 	: '',
								label 	: 'N° documento',
								props 	: propsObjTableEstado,
								button 	: {
									funcion : 'showDocumentsChild',
									icoFont : 'visibility',
									data 	: i.id
								}
							}
						}
					}else{
						numeroDocumento = {
							value 	: '-',
							label 	: 'N° documento',
							props 	: propsObjTableAll
						}
					} 
					// Formateando Data
					let objTable = {            
						id : {
							value: i.proyecto.id,
							label: 'ID',
							props: propsObjTableAll
						},
						proyecto : {
							value: i.proyecto.nombre.toLowerCase(),
							label: 'Proyecto',
							props: propsObjTableAll
						},
						tipo : {
							value: i.proyecto.credito_tipo.valor,
							label: 'Tipo',
							props: propsObjTableAll
						},
						nDoc: numeroDocumento,
						tirPpto: {
							value: i.proyecto.tir+'%',
							label: '% TIR PPTO',
							props: propsObjTableAll
						},
						tirMes: {
							value: ( i.proyecto.tir / 12 ).toFixed(2).toString()+'%',
							label: '% TIR Mes',
							props: propsObjTableAll
						},
						fecha: {
							value: moment(i.created_at).format("DD-MM-YYYY"),
							label: 'Fecha Inversión',
							props: propsObjTableAll
						},
						montoInversion: {
							// @ts-ignore
							value: i.proyecto.proyecto_moneda.moneda.simbolo + i.monto_a_invertir,
							label: 'Inversión',
							props: propsObjTableAll
						},
						montoDerechos: {
							// @ts-ignore
							value: i.proyecto.proyecto_moneda.moneda.simbolo + i.derechos,
							label: 'Derechos',
							props: propsObjTableAll
						},
						montoRecibido: {
							// @ts-ignore
							value: i.proyecto.proyecto_moneda.moneda.simbolo + i.inversionista_flujos.filter((flujo: any) => flujo.pagado == 1)
														.map((flujo: any) => flujo.flujo_real)
														.reduce((last: any, current: any) => last + current, 0),
							label: 'Monto recibido',
							props: propsObjTableAll
						},
						montoRecibir: {
							// @ts-ignore
							value: i.proyecto.proyecto_moneda.moneda.simbolo + i.inversionista_flujos
											.map((flujo: any) => flujo.flujo_inv)
											.reduce((last: any, current: any) => last + current, 0) ,

							label: 'Monto a recibir',
							props: propsObjTableAll
						},
						estado: {
							value: '',
							label: 'Estado',
							props: propsObjTableEstado,
							component: {
								name: 'ProjectStatus',
								props: {
									'project-status' : i[2]
								}
							}
						},
						acciones: {
							value: '',
							label: 'Acciones',
							props: propsObjTableEstado,
							buttons: [
								{
									funcion: 'getInvestById2Child',
									icoFont: 'credit_card',
									data: i.id
								},
								{
									funcion: 'showCommentsChild',
									icoFont: 'chat',
									data: i.id
								}								
							]
						}
					}
          // console.log('ESTOS SON LOS DATOS ');
          // console.log(objTable);
					i.datosEnTabla = objTable;
					return (
						this.$data.name.includes(i.proyecto.nombre) ||
							(	moment(i.created_at).isValid()
								? this.$data.date.includes(
								moment(i.created_at).format("DD-MM-YYYY")
							)
							: false) ||
						this.$data.amount.includes(i.proyecto.monto) ||
						    this.$data.id.includes(i.proyecto.id) ||
						this.$data.state.includes(i[2])
					);
				});
			},
    setOpenMenuPanel(){
      return this.$store.state.openMenuPanel;
    }
  },
  data() {
    return {
      SelectedCountry,
      RedCapital,
      paginationD: {
        sortBy: "created_at",
        descending: true
      },
      canShow: false,
      filter: true,
      test: null,
      filteredInvestments: null,
      newInvestments: null,
      filters: {
        id: null,
        name: null,
        date: null,
        amount: null,
        state: null
      },
      id: "",
      name: "",
      date: "",
      amount: "",
      state: "",
      search: "",
      datatable: [],
      investments: [],
      states: [],
      exportStates: null,
      documents: false,
      dialog: false,
      showId: null,
      viewFees: false,
      dataPagos: [{
        monto_fecha:null,
        monto_recibir:null,
        cuota_pagar:null,
        prox_cuota:null,
        pagado:null
      
      }],
      now: moment(),
      exportInvest: null,
      headersDocuments: [
        {
          text: "Nombre deudor",
          value: "deudor"
        },
        {
          text: "Rut",
          value: "rut"
        },
        {
          text: "Folio",
          value: "folio"
        },
        {
          text: "Monto",
          align: "center",
          value: "monto"
        },
        {
          text: "Fecha vencimiento",
          align: "center",
          value: "fecha_vencimiento"
        }
      ],

      tablaData: {
					headers: [
						{
							text: "ID",
							align: "center",
							value: "proyecto.id",
						},{
							text: "Proyecto",
							align: "center",
							value: "proyecto.nombre",
						},{
							text: "Tipo",
							align: "center",
							value: "proyecto.credito_tipo_id",
						},{
							text: "N° documento",
							align: "center",
							sortable: false,
						},{
							text: "% TIR PPTO",
							align: "center",
							value: "proyecto.tir",
						},{
							text: "% TIR Mes",
							align: "center",
							value: "proyecto.tir",
						},{
							text: "Fecha inversion",
							align: "center",
							value: "created_at",
						},{
							text: "Inversión",
							align: "center",
							value: "monto",
						},{
							text: "Derechos",
							align: "center",
							value: "derechos",
						},{
							text: "Monto recibido",
							align: "center",
							value: "proyecto.monto_entregado",
						},{
							text: "Monto a recibir",
							align: "center",
							value: null,
						},{
							text: "Estado",
							align: "center",
							value: "proyecto.estado.id",
							sortable: false,
						},{
							text: "Acciones",
							align: "center",
							sortable: false,
						},
					],
					msjEmpty: 'No existen registros en tu cuenta',
					rowsPerPage: {
						txt: 'Registro por página',
						itm: [10, 25, 50, { text: 'Todos', value: -1 }]
					},
					pagination: {
						sortBy: "created_at",
						descending: true,
						page: 1
					},
				},



      headers: [
        {
          text: "ID",
          align: "center",
          value: "proyecto.id",
          sortable:false,
        },
        {
          text: "Proyecto",
          align: 'center',
          value: "proyecto.nombre"
        },
        {
          text: "Tipo",
          align: "center",
          value: "proyecto.credito_tipo_id",
          sortable: false,
        },
        // {
        //   text: 'Id inversion',
        //   value: 'id',
        // },
        {
          text: "N doc",
          align: "center",
          sortable: false
        },
        {
          text: "% TIR PPTO",
          align: "center",
          value: "proyecto.tir",
          sortable:false,
        },
        {
          text: "% TIR Mes",
          align: "center",
          value: "proyecto.tir",
          sortable:false,
        },
        // {
        //   text: '% TIR Real',
        //   align: 'center',
        //   value: 'proyecto.tir'
        // },
        {
          text: "Fecha inversion",
          align: "center",
          value: "created_at"
        },
        {
          text: "Inversión",
          align: "center",
          value: "monto"
        },
        {
          text: "Derechos",
          align: "center",
          value: "derechos"
        },
        {
          text: "Monto recibido",
          align: "center",
          width: "10px",
          value: "proyecto.monto_entregado"
        },
        {
          text: "Monto a recibir",
          align: "center",
          value: null
        },
        {
          text: "Estado",
          align: "center",
          value: "proyecto.estado.id",
          sortable: false,
        },
        {
          text: 'Pagos',
          align: "center",
          sortable: false,
        },
        {
          text: "Comentarios",
          align: "center",
          sortable: false
        }
      ],
       headerpagos:[
        {
          text: "Numero cuota",
          align: "center",
          sortable: false
        },
        {
          text: "Vencimiento Cuota",
          align: "center",
          sortable: false
        },
        {
          text: "Monto Cuota",
          align: "center",
          sortable: false
        },
        {
          text: "Monto pagado a la Fecha",
          align: "center",
          sortable: false
        },
        {
          text: "Estado cuota",
          align: "center",
          sortable: false
        }
      ]
    };
  },
  async beforeMount() {
    API.getMyInvestments()
      .then((investments: any) => {
        this.$data.investments = investments;
        this.$data.investments.forEach((el:any) => {
          el.monto_a_invertir = parseFloat(el.monto_a_invertir)
          el.derechos = parseFloat(el.derechos)
        });
        this.$data.filters.id = investments
          .map((investment: any) => investment.proyecto.id)
          .filter((val: any) => val !== null);
        this.$data.filters.name = investments
          .map((investment: any) => investment.proyecto.nombre)
          .filter((val: any) => val !== null);
        this.$data.filters.date = investments
          .map((investment: any) => investment.created_at)
          .filter((val: any) => moment(val).isValid())
          .map((val: any) => moment(val).format("DD-MM-YYYY"));
        this.$data.filters.amount = investments
          .map((investment: any) => investment.proyecto.monto)
          .filter((val: any) => val !== null);
        // this.$data.filters.state = investments.map((investment: any) => investment.proyecto.estado_proyecto.valor).filter((val: any) => val !== null)
        API.getMyStates()
          .then((states: any) => {
            // console.log(states)
            this.$data.states = states;
            this.$data.filters.state = states
              .map((i: any) => i[2])
              .filter((v: any) => v !== null);
            let arr = this.$data.investments.map((item: any, i: any) =>
              Object.assign({}, item, states[i])
            );
            this.$data.newInvestments = arr;
            this.$data.exportStates = states
              .map((i: any) => i[3])
              .filter((v: any) => v !== null);
            this.$data.canShow = true;
          })
          // @ts-ignore
          .finally((x: any) => {
            let inv = this.$data.newInvestments.map((i: any) => {
              return {
                proyecto_id: i.proyecto_id,
                proyecto_nombre: i.proyecto.nombre,
                proyecto_tipo: i.proyecto.credito_tipo.valor.toUpperCase(),
                proyecto_tir_ppto: i.proyecto.tir + " %",
                proyecto_tir_mes: (i.proyecto.tir / 12).toFixed(2) + " %",
                proyecto_fecha_inversion: moment(i.created_at).format(
                  "DD-MM-YYYY"
                ),
                // @ts-ignore
                proyecto_inversion: this.$options.filters.currency(
                  i.monto_a_invertir
                ),
                // @ts-ignore
                proyecto_derecho: this.$options.filters.currency(i.derechos),
                // @ts-ignore
                proyecto_recibido: this.$options.filters.currency(
                  i.inversionista_flujos
                    .filter((flujo: any) => flujo.pagado == 1)
                    .map((flujo: any) => parseFloat(flujo.flujo_real))
                    .reduce((last: any, current: any) => last + current, 0)
                ),
                // @ts-ignore
                proyecto_a_recibir: this.$options.filters.currency(
                  i.inversionista_flujos
                    .map((flujo: any) => parseFloat(flujo.flujo_inv))
                    .reduce((last: any, current: any) => last + current, 0)
                ),
                /* proyecto_estado: this.$data.states.filter((x: any) => x[3] == i.proyecto_id).map((x: any) => x[2]).toString().toUpperCase(), */
                // @ts-ignore
                proyecto_moneda: RedCapital[SelectedCountry].currency.Name,
                proyecto_cuota_por_pagar:
                  i.inversionista_flujos.filter(
                    (flujo: any) => flujo.pagado == 0
                  ).length > 1
                    ? i.inversionista_flujos.filter(
                        (flujo: any) => flujo.pagado == 0
                      )[0].vencimiento
                    : "-",
                proyecto_proxima_cuota:
                  i.inversionista_flujos.filter(
                    (flujo: any) => flujo.pagado == 0
                  ).length > 1
                    ? i.inversionista_flujos.filter(
                        (flujo: any) => flujo.pagado == 0
                      )[0].vencimiento
                    : "-",
                proyecto_estado: i[2].toUpperCase(),
                proyecto_comentarios:
                  i.proyecto.comentarios.length > 0
                    ? (
                        i.proyecto.comentarios.length + " COMENTARIOS"
                      ).toUpperCase()
                    : "SIN COMENTARIOS"
              };
            });
            this.$data.exportInvest = inv;
          });
      })
      .catch(err => {
        console.log("Error " + err);
      });
  },
  methods: {
    showFees(id: number) {
      this.$data.showId = id;
      this.$data.viewFees = true;
    },
    showComments(id: number) {
      this.$data.showId = id;
      this.$data.dialog = true;
    },
    showDocuments(id: number) {
      this.$data.showId = id;
      this.$data.documents = true;
    },
    getInvestById(id: number) {
      return this.$data.investments.find((el: any) => el.id == id);
    },
    getInvestById2(id: number) {
      this.$data.showId = id;
      this.$data.viewFees = true;

      let i = this.$data.investments.find((el: any) => el.id == id)
      this.$data.dataPagos[0].monto_fecha= i.inversionista_flujos.map((flujo:any) => parseFloat(flujo.flujo_real))
                  .reduce((last:any, current:any) => last + current, 0)
      this.$data.dataPagos[0].monto_recibir = i.inversionista_flujos.map((flujo:any) => parseFloat(flujo.flujo_inv))
                  .reduce((last:any, current:any) => last + current, 0)
      this.$data.dataPagos[0].cuota_pagar = i.inversionista_flujos.filter(
                    (flujo:any) => flujo.pagado == 0
                  ) 
      this.$data.dataPagos[0].prox_cuota = i.inversionista_flujos.filter(
                    (flujo:any) => flujo.pagado == 0
                  )
      this.$data.dataPagos[0].pagado = i.inversionista_flujos[0].pagado 

    },
    resetFilters() {
      this.$data.id = "";
      this.$data.name = "";
      this.$data.date = "";
      this.$data.amount = "";
      this.$data.state = "";
    },
    color(id: number) {
      return this.$data.colors.find((color: any) => color.id == id).color;
    },
    fees() { 
      if (this.$data.investments == null) {
        return [];
      }
      if(this.$data.showId){
       return this.$data.investments.find((el: any) => el.id == this.$data.showId).inversionista_flujos
        //.inversionista_flujos; /* .filter((i: any) => i.flujo_inv > 0) */
        }
    },
  },
  components: {
    ProjectStatus,
    TableResponsive,
  }
})
export default class Investments extends Vue {}
