




























import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  data() {
    return {
      colors: [
        {
          id: 1,
          text: "Al día",
          color: "#58FA82"
        },
        {
          id: 2,
          text: "En mora",
          color: "#FFFF00"
        },
        {
          id: 3,
          text: "Mora > 90 Días",
          color: "#FF0040"
        },
        {
          id: 4,
          text: "Mora > 180 días",
          color: "#FF0040"
        },
        {
          id: 5,
          text: "Pagado/Cerrado",
          color: "#400000"
        },
        {
          id: 6,
          text: "Pagado Cerrado",
          color: "#400000"
        },
        {
          id: 7,
          text: "Cerrado pendiente intereses",
          color: "#400000"
        }
      ]
    };
  },
  methods: {
    color(id: number) {
      return this.$data.colors.find((color: any) => color.text == id).color;
    }
  }
})
export default class ProjectStatus extends Vue {
  @Prop() projectStatus!: any;
}
