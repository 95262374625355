







































































































































































import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import { RedCapital, SelectedCountry, State } from "@/configuration";
import NavBar from "@/components/NavBar.vue";
import LNavbar from "@/components/LandingNavBar.vue";
import NavBarDashboard from "@/components/dashboard/NavBar.vue";
import Login from "@/components/Login.vue";
import Help from "@/components/Help.vue";
import ToTop from "@/components/ToTop.vue";
import ProjectInfo from "@/components/ProjectInfo.vue";
import Footer from "@/components/Footer.vue";
import SnackError from "@/components/SnackError.vue";
import { EventBus } from "@/main";
import API from "@/api";
import ButtonPanel from "@/components/panel/ButtonPanel.vue";
import SideBarPanel from "@/components/panel/SideBarPanel.vue";


@Component({
  computed: {
    ...mapState(["user", "business", "menu", "businesSelected", "estado_sunat", "estado_cavali"]),
    canShowPanelOptions() {
      return this.$route.path.startsWith("/panel/") || this.$route.path.startsWith("/panel-facto/");
    },
    getPadding() {
      if (!this.$data.isMobile) {
        return "px-5";
      } else {
        return "";
      }
    }
  },
  watch: {
    businesSelected: {
      handler(newVal) {
        if(newVal != null){
          //@ts-ignore
          this.estadoEmpresaCavali();
          //@ts-ignore
          this.estadoEmpresaSunat();
          //@ts-ignore
          this.miscuentas();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      RedCapital,
      SelectedCountry,
      State,
      showPendingPayments: false,
      pendingPayments: null,
      overlay: false,
      error: {
        canShow: false,
        message: ""
      },
      success: {
        canShow: false,
        message: ""
      },
      headers: [
        {
          text: "Nombre proyecto"
        },
        {
          text: "Código proyecto"
        },
        {
          text: "Fecha inversión"
        },
        {
          text: "Monto"
        },
        {
          text: "Derechos"
        },
        {
          text: "Saldo pendiente"
        },
        {
          text: "Pago"
        }
      ],
      isMobile: false,
      cuentas_cargadas: [],
    };
  },
  created() {
    EventBus.$on("logout", () => {
      this.$store.commit("logout");
      this.$store.commit('setBusinessSelected',null)
      this.$router.push({
        path: "/"
      });
    });
  },
  async beforeMount() {
    if (this.$store.state.business != null) {
      this.$store.commit("setBusiness", null);
    }
    if (this.$store.state.user != null) {
      API.getPersonalData(this.$store.state.user.id)
        .then(user => {
          this.$store.commit("setUser", user);
        })
        .catch(error => {
          EventBus.$emit("logout");
        });
    }

    if (this.$store.state.user != null) {
      this.$data.pendingPayments = await API.getPendingPayments();

      // @ts-ignore
      if (this.totalInvestments() > 0) {
        if (
          !this.$route.path.startsWith("/panel/transferencias-pendientes") &&
          !this.$route.path.startsWith("/panel/pago-pendiente") &&
          !this.$route.path.startsWith("/panel/informar-pago")
        ) {
          this.$data.showPendingPayments = this.$store.getters.canShowPendingPayments(
            this.$data.pendingPayments.map((payment: any) => payment.id)
          );
        }
      }
    }

    setInterval(() => {
      if (this.$store.state.user == null) {
        return;
      }

      let expireTime: Date = new Date(this.$store.state.expireTime);

      let thirtyMinutes = new Date(this.$store.state.expireTime);
      thirtyMinutes.setMinutes(thirtyMinutes.getMinutes() - 30);

      if (
        Date.now() > expireTime.getTime() ||
        Date.now() >= thirtyMinutes.getTime()
      ) {
        EventBus.$emit("open-login-dialog");
      }
    }, 30 * 1000);
  },
  mounted() {
    this.$data.overlay = false;
    EventBus.$on("error-dialog", (canShow: boolean, message: string) => {
      this.$data.error.canShow = canShow;
      this.$data.error.message = message;
    });

    EventBus.$on("success-dialog", (canShow: boolean, message: string) => {
      this.$data.success.canShow = canShow;
      this.$data.success.message = message;
    });

    // @ts-ignore
    this.onResponsive();
    // @ts-ignore
    window.addEventListener("resize", this.onResponsive);

    //@ts-ignore
    this.estadoEmpresaSunat(); // Llamada inicial al método
    
    //@ts-ignore
    this.estadoEmpresaCavali(); // Llamada inicial al método
    
    setInterval(() => {
      //@ts-ignore
      if(this.estado_sunat == 2){
        //@ts-ignore
        this.estadoEmpresaSunat(); 
      }
      //@ts-ignore
      if(this.estado_cavali == 2){
        //@ts-ignore
        this.estadoEmpresaCavali();
      }
    }, 10000);

  },
  beforeDestroy() {
    // @ts-ignore
    window.removeEventListener("resize", this.onResponsive);
    EventBus.$off("error-dialog");
  },
  methods: {
    onScroll() {
      EventBus.$emit("app-scroll-event", window.pageYOffset);
    },
    onResponsive() {
      if (window.innerWidth <= 960) {
        this.$data.isMobile = true;
      } else {
        this.$data.isMobile = false;
      }
    },
    totalAmount() {
      return this.$data.pendingPayments
        .map((investment: any) => investment.saldo_pendiente_real)
        .reduce((last: number, current: number) => last + current, 0);
    },
    totalInvestments() {
      return this.$data.pendingPayments.length;
    },
    updateVisibilityPayments() {
      let ids = this.$data.pendingPayments.map(
        (investment: any) => investment.id
      );
      this.$store.commit("setPendingPaymentsIds", ids);
      this.$data.showPendingPayments = false;
    },
    estadoEmpresaSunat(){
      const api_key = 'key_onboarding_prod_fnPqT5xQEi5Vcb9wKwbCf65c3BjVGyBB';
      //@ts-ignore
      let empresa_id = this.businesSelected.id;
      API.factoring.estadoEmpresaSunat(empresa_id, api_key).then((response) => {
        const res = response.data;
        //estados : 1 -> enrolada
        //estados : 2 -> revision
        //estados : 3 -> clave incorrecta
        let estado = res.login_estado_id;
        if(estado == 1){
          this.$store.commit("setUserValid", true);
          this.$store.commit("setEstadoSunat", 1);
        }
        else if(estado == 2){
          this.$store.commit("setUserValid", false);
          this.$store.commit("setEstadoSunat", 2);
        }
        else {
          this.$store.commit("setUserValid", false);
          this.$store.commit("setEstadoSunat", 3);
        }

      })
    },
    estadoEmpresaCavali(){
      const api_key = 'key_onboarding_prod_fnPqT5xQEi5Vcb9wKwbCf65c3BjVGyBB';
      //@ts-ignore
      let empresa_id = this.businesSelected.rut;
      API.estadoEmpresaCavali(empresa_id, api_key).then((response) => {
        const res = response.data[0];
        //estados : 1 -> enrolada
        //estados : 2 -> revision
        //estados : 3 -> clave incorrecta
        let estado = res.login_estado_id;
        if(estado == 1){
          this.$store.commit("setEstadoCavali", 1);
        }
        else if(estado == 2){
          this.$store.commit("setEstadoCavali", 2);
        }
        else {
          this.$store.commit("setEstadoCavali", 3);
        }

      })
    },
    miscuentas(){
        API.factoring
        .get_cuentas(this.$store.state.businesSelected.id)
        .then((res: any) => {
            this.$data.cuentas_cargadas = res.mis_cuentas;
            //@ts-ignore
            this.$store.commit("setCuentasBancarias", this.cuentasBancariasValidas());
            
        })
        .catch((err: any) => {});
    },
    cuentasBancariasValidas(){
        let validCuentas = this.$data.cuentas_cargadas.every((cuenta:any) =>  cuenta.validado === 1);
        return validCuentas;
    }
  },
  components: {
    LNavbar,
    NavBar,
    NavBarDashboard,
    Login,
    Help,
    ToTop,
    ProjectInfo,
    Footer,
    SnackError,
    ButtonPanel,
    SideBarPanel,
  }
})
export default class App extends Vue {}
