export const Menus = [
  {
    icon: "insert_chart",
    text: "Mis inversiones",
    categories: [
      {
        icon: "maps",
        text: "Resumen",
        to: {
          name: "dashboard-investor-my-investments"
        }
      },
      {
        icon: "receipt",
        text: "Transferencias Pendientes",
        to: {
          name: "dashboard-investor-pending-payments"
        }
      },
      {
        icon: "receipt",
        text: "Estado de Pagos",
        to: {
          name: "dashboard-investor-payment-status"
        }
      },
      /*{
        icon: 'receipt',
        text: 'Detalle Cuotas',
        to: {
          name: 'dashboard-investor-details-cuotas',
        },
      },*/
      {
        icon: "receipt",
        text: "Detalle Pagos",
        to: {
          name: "dashboard-investor-payment-details"
        }
      }
      /*{
        icon: 'receipt',
        text: 'Cartola Mensual',
        to: {
          name: 'dashboard-investor-cartola-mensual',
        },
      },
      {
        icon: 'receipt',
        text: 'Cartola Anual',
        to: {
          name: 'dashboard-investor-cartola-anual',
        },
      },*/
      // {
      //   icon: 'insert_chart',
      //   text: 'Mis inversiones',
      //   links: [
      //     {
      //       icon: 'maps',
      //       text: 'Resumen Inversiones (RI)',
      //       to: {
      //         name: 'dashboard-investor-my-investments',
      //       },
      //     },
      //     {
      //       icon: 'receipt',
      //       text: 'Transferencias Pendientes',
      //       to: {
      //         name: 'dashboard-investor-pending-payments',
      //       },
      //     },
      //   ],
      // },
    ]
  },
  // {
  //   allow: [1],
  //   icon: 'dashboard',
  //   text: 'Administrador',
  //   categories: [
  //     {
  //       icon: 'folder_special',
  //       text: 'Proyectos',
  //       links: [
  //         {
  //           icon: 'work',
  //           text: 'Accion en algun p',
  //         },
  //       ],
  //     }
  //   ],
  // },
];

export const Profile = {
  icon: "person",
  text: "Mis datos",
  links: [
    {
      icon: "assignment",
      text: "Datos personales",
      to: {
        name: "dashboard-personal-data"
      }
    },
    {
      icon: "account_balance",
      text: "Cuenta Bancaria",
      to: {
        name: "dashboard-investor-bank-account"
      }
    },
    {
      icon: "lock",
      text: "Cambiar contraseña",
      to: {
        name: "dashboard-change-password"
      }
    }
    /*{
      icon: 'star',
      text: 'Socio club',
      to: {
        name: 'dashboard-investor-pay-membership',
      },
    },*/
    // {
    //   icon: 'import_export',
    //   text: 'Mis movimientos',
    //   to: {
    //     name: 'dashboard-my-movements',
    //   },
    // },
  ]
};

export const MyBusiness = {
  icon: "business",
  text: "Mis empresas",
  to: {
    name: "dashboard-my-business"
  }
};

export const MyDocuments = {
  icon: "folder_special",
  text: "Mis documentos",
  active: false,
  links: [
    {
      icon: "chrome_reader_mode",
      text: "Contratos",
      to: {
        name: "dashboard-contracts"
      }
    },
    // {
    //   icon: "work",
    //   text: "Mandatos",
    //   to: {
    //     name: "dashboard-terms"
    //   }
    // },
    // {
    //   icon: "work",
    //   text: "Declaraciones SII",
    //   to: {
    //     name: "dashboard-declaration"
    //   }
    // },
    {
      icon: "maps",
      text: "Pagarés y respaldos",
      to: {
        name: "dashboard-iou-and-certificates"
      }
    }
  ]
};
/* Menus inversionista */
export const redCapitalInversionistaMenus = [
  {
    icon: "description",
    text: "Panel inversionista",
    to: {
      name: "dashboard-stats"
    }
  },
  {
    icon: "description",
    text: "Resumen inversiones",
    to: {
      name: "dashboard-investor-my-investments"
    }
  },
  {
		icon: "pending_actions",
		text: "Transferencias pendientes",
    to: {
      name: "dashboard-investor-pending-payments"
    }
	}
]

/* Menu lateral Logeado Perú */
export const dashboardLogeado = [
  {
		icon: "dashboard",
		text: "Mi panel",
		to: {
      path: "/panel/estadisticas"
    }
	},
  {
		icon: "paid",
		text: "Mis inversiones",
		categories: [
			{
				icon: "description",
				text: "Resumen",
				to: {
          name: "dashboard-investor-my-investments"
        }
			},
      {
        icon: "pending_actions",
        text: "Transferencias pendientes",
        to: {
          name: "dashboard-investor-pending-payments"
        }
      },
      {
        icon: "request_quote",
        text: "Estado de Pagos",
        to: {
          name: "dashboard-investor-payment-status"
        }
      },
      {
        icon: "payments",
        text: "Detalle Pagos",
        to: {
          name: "dashboard-investor-payment-details"
        }
      }
		]
	},
  /* {
    icon: "drafts",
    text: "Mis solicitudes",
    categories: [
      {
        icon: "folder_special",
        text: "Pendientes",
        to: {
          name: "dashboard-applicant-requests"
        }
      },
      {
        icon: "receipt",
        text: "Aprobadas",
        to: {
          name: "dashboard-applicant-my-projects"
        }
      }
    ]
  }, */
  {
		icon: "business",
		text: "Mis empresas",
    to: {
      name: "dashboard-my-business"
    }
	},
  // Mis Datos
	{
		icon: "person",
		text: "Mis datos",
		to: {
      name: "dashboard-personal-data"
    }
	}, // 
  {
		icon: "folder_special",
		text: "Mis documentos",
		active: false,
		categories: [
			{
				icon: "history_edu",
				text: "Contratos",
				to: {
          name: "dashboard-contracts"
        }
			},
			{
				icon: "shield",
				text: "Pagarés y respaldos",
				to: {
          name: "dashboard-iou-and-certificates"
        }
			}
		]
	}
];

/**
 * Menu Solicitante 
 * General
 */
 export const panelSolicitanteMenus = [
	{
		icon: "dashboard",
		text: "Mi panel",
		to: {
			path: "/panel-facto/estadisticas"
		}
	},
	{
		icon: "receipt",
		text: "Anticipar facturas",
		to: {
			path: "/panel-facto/anticipo-facturas"
		}
	},
  {
		icon: "receipt_long",
		text: "Cargar facturas",
		to: {
			path: "/panel-facto/cargar-facturas"
		}
	},
  //comentar simultaneamente hasta que se tenga el flujo para ceder facturas
	// {
	// 	icon: "receipt_long",
	// 	text: "Ceder facturas",
	// 	to: {
	// 		path: "/panel-facto/ceder-facturas"
	// 	}
	// },
	{
		icon: "topic",
		text: "Mis operaciones",
		to: {
			path: "/panel-facto/mis-operaciones"
		}
	},
	{
		icon: "person",
		text: "Mis datos",
		to: {
			path: "/panel-facto/datos-personales"
		}
	},
  {
		icon: "lock",
		text: "Mis claves",
		to: {
			path: "/panel-facto/mis-claves"
		}
	},
	{
		icon: "business",
		text: "Mis empresas",
		to: {
			path: "/panel-facto/empresas"
		}
	},
];
/**
 * Menu Solicitante del tipo de via_id = 2
 */
export const panelSolicitanteMenus2 = [
	{
		icon: "dashboard",
		text: "Mi panel",
		to: {
			path: "/panel-facto/estadisticas"
		}
	},
	{
		icon: "list",
		text: "Mis Solicitudes",
		to: {
			path: "/panel-facto/solicitante/solicitudes"
		}
	},
	{
		icon: "person",
		text: "Mis datos",
		to: {
			path: "/panel-facto/datos-personales"
		}
	},
	/* {
		icon: "/images/panel/solicitante/ico_misDatos.svg",
		text: "Mis datos",
		to: {
			path: "/panel-facto/cuenta-banco"
		}
	} */
];

export const redCapitalSolicitanteMenus = [
	{
		icon: "attach_money",
		text: "Invertir",
		to: {
			name: "investment"
		}
	},
	{
		icon: "person",
		text: "Panel Solicitante",
		to: {
			path: "/panel-facto/estadisticas"
		}
	},
]

export const panelInversionistaMenus = [];