import { authenticationRequired } from "@/authentication";
export default [
    {
        path: "/actividad/administracion",
        name: "AdministracionDashboard",
        component: () =>
          import(/* webpackChunkName: "dashboardactividad" */ "@/views/actividad/dashboard.vue"),
        beforeEnter: authenticationRequired
      },
      {
        path: "/actividad/admin-user",
        name: "AdministracionDashboardUser",
        component: () =>
          import(/* webpackChunkName: "dashboardactividad" */ "@/views/actividad/dashboarduser.vue"),
        beforeEnter: authenticationRequired
      },
      {
        path: "/actividad/sabermas",
        name: "sabermas",
        component: () =>
          import(/* webpackChunkName: "sabermas" */ "@/views/actividad/Sabermas.vue"),
        beforeEnter: authenticationRequired,
      },
      {
        path: "/actividad/administrarfinanzas",
        name: "AdministracionOrganiza",
        component: () =>
          import(/* webpackChunkName: "AdministracionOrganiza" */ "@/views/actividad/AdministracionActividad1.vue"),
        beforeEnter: authenticationRequired
      },
      {
        path: "/actividad/administrarcostos",
        name: "AdministracionCostos",
        component: () =>
          import(/* webpackChunkName: "dashboard-controla-tus-costos" */ "@/views/actividad/AdministracionActividad2.vue"),
        beforeEnter: authenticationRequired
      },
      {
        path: "/actividad/administrarfuturo",
        name: "AdministracionFuturo",
        component: () =>
          import(/* webpackChunkName: "dashboard-planifica-tu-futuro" */ "@/views/actividad/AdministracionActividad3.vue"),
        beforeEnter: authenticationRequired
      },
      {
        path: "/actividad/administrardeuda",
        name: "AdministracionDeuda",
        component: () =>
          import(/* webpackChunkName: "dashboard-gestiona-tu-deuda" */ "@/views/actividad/AdministracionActividad4.vue"),
        beforeEnter: authenticationRequired
      },
      {
        path: "/actividad/organiza/",
        name: "actividad-organiza-tus-finanzas",
        component: () =>
          import(/* webpackChunkName: "actividad-organiza-tus-finanzas" */ "@/views/actividad/OrganizaTusFinanzas.vue"),
        beforeEnter: authenticationRequired,
        props: true
      },
      {
        path: "/actividad/costos",
        name: "controla-tus-gastos",
        component: () =>
          import(/* webpackChunkName: "controla-tus-gastos" */ "@/views/actividad/ControlaTusCostos.vue"),
        beforeEnter: authenticationRequired,
        props: true
      },
      {
        path: "/actividad/metasacortoplazo",
        name: "metas-a-corto-plazo",
        component: () =>
          import(/* webpackChunkName: "metas-a-corto-plazo" */ "@/views/actividad/MetasaCortoPlazo.vue"),
        beforeEnter: authenticationRequired,
        props: true
      },
      {
        path: "/actividad/gestionatudeuda",
        name: "gestiona-tu-deuda",
        component: () =>
          import(/* webpackChunkName: "gestiona-tu-deuda" */ "@/views/actividad/GestionaTuDeuda.vue"),
        beforeEnter: authenticationRequired,
        props: true
      },
  ]