




































import { Component, Prop, Vue } from "vue-property-decorator";
import { EventBus } from "@/main";

@Component({
  data() {
    return {
      selector: "#first",
      selected: "Button",
      elements: ["Button", "Radio group"],
      duration: 500,
      offset: 768,
      easing: "linear",
      showFrom: 400,
      offsetTop: 0
    };
  },
  beforeMount() {
    EventBus.$on("app-scroll-event", (scroll: number) => {
      this.$data.offsetTop = scroll;
    });
  },
  computed: {
    options() {
      return {
        duration: this.$data.duration,
        offset: this.$data.offset,
        easing: this.$data.easing
      };
    }
  }
})
export default class Help extends Vue {}
