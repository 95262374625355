
























































































































































































































	import DniField from "@/components/DniField.vue";
	import { Component, Vue } from "vue-property-decorator";
	import { mapState } from "vuex";
	import ReusableButton from "@/components/ReusableButton.vue";
	// @ts-ignore
	import { Tabs, TabItem } from 'vue-material-tabs';
	import ShowLoading from "@/components/panel/ShowLoading.vue";
	import { EventBus } from "@/main";
	import API from "@/api";
	import {
		SelectedCountry,
		RedCapital,
		Countries,
		TitleTemplateDashboard,
	} from "@/configuration";

	@Component({
		metaInfo: {
			title: "Datos personales",
			titleTemplate: TitleTemplateDashboard,
			htmlAttrs: {
				// @ts-ignore
				lang: RedCapital[SelectedCountry].Lang,
			},
		},
		computed: {
			...mapState(["user","setOpenMenuPanel"]),
			setOpenMenuPanel(){
				return this.$store.state.openMenuPanel;
			},
		},
		data() {
			return {
				dialog2:false,
				dialog: false,
				SelectedCountry,
				RedCapital,
				canShow2: true,
				btn: {
					clickable: false,
					loading: false,
					isOk: null,
					successText: "Guardado correctamente",
					errorText: "Error interno",
				},
				deparment_id: null,
				province_id: null,
				userData: null,
				deparments: null,
				formatted: null,
				provinces: [],
				districts: [],
				rules: {
					notEmpty: [(v: string) => !!v || "Este campo es requerido"],
					email: [
						(v: string) =>
							/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
							"El mail debe ser válido",
					],
				},
				staticCountries: Countries,
				countries: null,
				civilStatus: [
					{
						id: "soltero",
						text: "Soltero(a)",
					},
					{
						id: "casado",
						text: "Casado(a)",
					},
					{
						id: "divorciado",
						text: "Divorciado(a)",
					},
					{
						id: "viudo",
						text: "Viudo(a)",
					},
				],
				genders: [
					{
						id: "masculino",
						text: "Masculino",
					},
					{
						id: "femenino",
						text: "Femenino",
					},
				],
				btn2: {
					clickable: false,
					loading: false,
					isOk: null,
					successText: "Contraseña actualizada",
					errorText: "Error al actualizar la contraseña",
				},
				passwords: {
					old: "",
					new: "",
					confirm: "",
				},
				show: {
					old: false,
					new: false,
					confirm: false,
				},
				rules2: {
					notEmpty2: [(v: any) => !!v || "Este campo es requerido"],
					password: [
						(v: any) => !!v || "Este campo es requerido",
						(v: any) =>
							v.length >= 6 || "Contraseña tiene que ser mayor o igual a 6",
					],
				},
				passwordsAreValid: false,
				menu: false

			};
		},
		watch: {
			userData: {
				handler(val) {
					if (this.$data.canShow2) {
						// @ts-ignore
						if (this.$refs.form.validate()) {
							// @ts-ignore
							this.$data.btn.clickable = this.hasChange();
						}
					}
				},
				deep: true,
			},
			passwords: {
				handler(val) {
					// @ts-ignore
					if (this.$refs.form2.validate()) {
						if (this.$data.passwords.new == this.$data.passwords.confirm) {
							this.$data.btn2.clickable = true;
						} else {
							this.$data.btn2.clickable = false;
						}
					} else {
						this.$data.btn2.clickable = false;
					}
				},
				deep: true,
			},
			menu (val: any) {
				// @ts-ignore
				val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
			}
		},
		async beforeMount() {
			API.getPersonalData(this.$store.state.user.id)
            .then(user => {
              //@ts-ignore
              this.$data.userData.distrito_id = user.distrito_id
              //@ts-ignore
              API.getDistrito_id(user.distrito_id)
              .then((res =>{
                //@ts-ignore
                // // console.log(res)
                //@ts-ignore
                this.$data.deparment_id = res.data.selected.departamento_id
                //@ts-ignore
               // // console.log(res.data.selected.id)
                //@ts-ignore
                this.$data.province_id = res.data.selected.id
                 API.getDistricts(
                   //@ts-ignore
                    res.data.selected.id
                  )
                  .then((res)=>{
                    this.$data.districts = res
                    // console.log(res)
                  })
                API.getProvinces(
                  //@ts-ignore
                    res.data.selected.departamento_id
                  ).then((res)=>{


                    this.$data.provinces = res
                   // this.$data.province_id
                    // console.log(res)
                  })
              }))
              .catch((error)=>{
                // console.log(error)
              })
             // this.$store.commit("setUser", user);
            })
            .finally(() => {
              // @ts-ignore
            //  this.resetUserData();
             // this.$data.btn.isOk = true;
            });
			// @ts-ignore
			this.resetUserData();
			this.$data.formatted = this.$store.state.user.rut;
			//this.$data.canShow2 = true;
			const format = this.$store.state.user.rut.split("");

		},
		methods: {
			volverpanel(){
				if(!this.$route.path.startsWith('/panel/')){
					this.$router.push({name: 'dashboard-factoring-My-info-acount-bank'})
				}
				// :to="{
				//     name: 'dashboard-factoring-My-info-acount-bank',
				//     }" 
			},
			async resetUserData() {
				let temp = {};
				Object.assign(temp, this.$store.state.user);
				this.$data.userData = temp;
				this.$data.userData.nit = this.$data.userData.rut;

				if (this.$data.userData.pais_id == null) {
					this.$data.userData.pais_id = 1;
				}
				// this.$data.regions = await API.getRegions(this.$data.userData.pais_id);

				// @ts-ignore
				this.updateDeparments();
				// @ts-ignore
				this.$data.btn.clickable = this.hasChange();
			},
			async updateDeparments() { //updateDeparments
				this.$data.deparments = await API.getDeparments();
				if (this.$data.deparment_id == null) {
					this.$data.deparment_id = this.$data.deparments[0].deparment_id;
			}

			// @ts-ignore
			this.updateProvinces();
			},
			async updateProvinces() { //updateProvinces
				this.$data.provinces = await API.getProvinces(
				this.$data.deparment_id
			);
			if (this.$data.provinces.length != 0) {
				if (this.$data.province_id == null) {
					this.$data.province_id = this.$data.provinces[0].province_id;
				}
			}
			this.$data.canShow = true;
			// @ts-ignore
			this.updateDistricts();
			},
			async updateDistricts() { //updateDistricts
				this.$data.districts = await API.getDistricts(
				this.$data.province_id
			);
			if (this.$data.districts.length != 0) {
				if (this.$data.userData.distrito_id == null) {
					this.$data.userData.distrito_id = this.$data.districts[0].district_id;
				}
			}
			this.$data.canShow = true;
			// @ts-ignore
			// this.updateProvinces();
			},
			hasChange() {
				let oldData = Object.values(this.$store.state.user);
				let newData = Object.values(this.$data.userData);

				let hasSomethingChange = false;

				for (let i in oldData) {
					if (oldData[i] != newData[i]) {
						if (newData[i] == "") {
							continue;
						}
						hasSomethingChange = true;
					}
				}

				return hasSomethingChange;
			},
			async saveChanges() {
				this.$data.dialog = true;
				this.$data.btn.loading = true;
				// @ts-ignore
				if (this.$refs.form.validate()) {
					API.updatePersonalData(this.$data.userData).then((data) => {
						this.$data.dialog = false;
						this.$data.dialog2 = true;
						// EventBus.$emit(
						// "snack-success",
						// "Datos actualiados correctamente"
						// );
						API.getPersonalData(this.$store.state.user.id)
							.then((user) => {
								// this.$data.dialog2 = false;
								this.$store.commit("setUser", user);
							})
							.finally(() => {
								// @ts-ignore
								this.resetUserData();
								this.$data.btn.isOk = true;
								// this.$data.dialog2 = false;
							});
					}).catch((error) => {
						this.$data.dialog = false;
					});
				}
			},
			changePassword() {
				this.$data.dialog = true;
				// @ts-ignore
				if (this.$refs.form2.validate()) {
					this.$data.btn2.loading = true;
					API.changePassword(
						this.$data.passwords.old,
						this.$data.passwords.new,
						this.$data.passwords.confirm
					)
						.then((res) => {
							this.$data.dialog2 = true;
							this.$data.dialog = false;
							this.$data.btn2.isOk = true;
							EventBus.$emit(
								"snack-success",
								"Contraseña actualizada correctamente"
							);
							this.$data.passwords.old = null;
							this.$data.passwords.new = null;
							this.$data.passwords.confirm = null;
							setTimeout(() => {
								this.$data.dialog2 = false;
							},2000)
						})
						.catch((error) => {
							this.$data.btn2.isOk = false;
							this.$data.dialog = false;
							EventBus.$emit("snack-error", "Error al actualizar contraseña");
						});
				}
			},
			save (date) {
				// @ts-ignore
				this.$refs.menu.save(date);
			},
		},
		components: {
			ReusableButton,
			DniField,
			Tabs,
			TabItem,
			ShowLoading
		},
	})
	export default class PersonalData extends Vue {}
