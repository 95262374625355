



































































































import { Component, Vue } from "vue-property-decorator";
import {
  TitleTemplateDashboard,
  SelectedCountry,
  RedCapital,
} from "@/configuration";
import DniPerson from "@/components/DniField.vue";
import { mapState } from "vuex";
import API from "@/api";
// @ts-ignore
import ChartJS from "chart.js";
import { EventBus } from "@/main";
import Axios from "axios";
var moment = require("moment");

@Component({
  computed: {
    uploadDisabled() {
      return this.$data.files.length === 0;
    },
  },
  data() {
    return {
        cuentas:false,
        valid:false,
        cuenta: {
            banco_id: null,
            tipo_id: null,
            numero: null,
            empresa_id: null,
            alias: null,
        },
        staticBusiness: "",
        accountTypes:'',
        banks:'',
        rules: {
            notEmpty: [(v: any) => !!v || "Ingrese un valor"],
            password: [(v: any) => !!v || "La contraseña es requerida"],
            numero: [
                (v: any) => !/[^\d]/gim.test(v) || "Tiene que ser solo numeros",
                (v: any) => !!v || "Ingrese un valor"
            ],
        },
    };
  },
async beforeMount() {
  this.$data.banks = await API.getBanks();
    this.$data.accountTypes = await API.getAccountTypes();
   EventBus.$on("registro_cuentas_bancarias", (v: any) => {
      this.$data.cuentas = v;
    });
    API.getBusiness()
      .then((res: any) => {
        this.$data.staticBusiness = res;
        this.$data.cuenta.empresa_id = this.$store.state.businesSelected.id;
      })
      .catch(() => {
      });
    
},
  methods: {
    guardar_cuenta(){
      API.factoring
        .guardar_cuenta_banco(this.$data.cuenta)
        .then((res: any) => {
          if(res.errors){
            if(res.errors.alias){
              EventBus.$emit("alertsError", true,'¡Atención!',res.errors.alias[0])
            }
            if(res.errors.numero){
              EventBus.$emit("alertsError", true,'¡Atención!',res.errors.numero[0])
            }
          }else{
            EventBus.$emit("snack-success", "Datos actualizados correctamente");
            EventBus.$emit("alerts", true, "Datos actualizados correctamente");
            this.$data.cuentas = false;
            this.$data.cuentas = false;
            this.$data.dialog = false;
            this.$data.cuenta.banco_id = "";
            this.$data.cuenta.tipo_id = "";
            this.$data.cuenta.numero = "";
            this.$data.cuenta.empresa_id = "";
            this.$data.cuenta.alias = "";

            this.$emit("registro_cuenta", res);
            setTimeout(() => {
              EventBus.$emit("alerts", false, "Datos actualizados correctamente");
            },2000)
          }
         
        })
        .catch((err: any)=>{
           EventBus.$emit("snack-error", "Error al actualizar tus datos");
        })
    },
  },
  components: {
  },
})
export default class RegistroCuentaBancaria extends Vue {}
