<template>
    <!-- Alerta para paneles con accion -->
    <div :class="tipo ? 'rc-alertaAccion__ok' : typeError">	
        <v-layout wrap row ma-3>
            <v-flex :class="!tipo && accion ? 'xs12 sm7 md7 lg8' : 'xs12 sm12 md12 lg12'">
                <v-layout class="rc-alertaAccion__text" row align-center>
                    <span>
                        <v-icon class="mr-3">{{tipo ? 'check_circle_outline' : 'error_outline'}}</v-icon>
                        <div v-html="mensaje"></div>
                    </span>
                    
                    <div v-if="tipo" class="rc-alertaAccion__accion">
                        <v-btn flat icon @click='emitClick'>
                            <v-icon>cancel</v-icon>
                        </v-btn>
                    </div>
                </v-layout> 
            </v-flex>
            <v-flex v-if="!tipo && accion" xs12 sm5 md5 lg4>
                <div class="rc-alertaAccion__accion">
                    <v-btn v-if="accion.to" class="rc-button_prim rc-alertaAccion__button" :to="accion.to">
                        {{accion.text}}
                    </v-btn>
                    <v-btn v-else class="rc-button_prim rc-alertaAccion__button" @click='emitClick'>
                        {{accion.text}}
                    </v-btn>
                </div>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    import { Component, Vue } from "vue-property-decorator";
	/* 
        Atención:
        - Este componente es utilizado para añadir alertas con acción
        - Puede recibir 3 props:
            1) mensaje: puede ser texto o html
            2) tipo: indica el tipo de alerta a utilizar, puede ser true 'Alerta Ok' o false 'Alerta Error'
            3) accion: este es un objeto con 2 elementos:
                * to : encargado de redireccionar a alguna URL, ej: /panel-facto/ceder-facturas
                * text : texto que aparece en el boton de accion
                * v-on:accionClick : donde se llama el componente ejecutara la accion del boton en la vista correspondiente
        EJ:
        Alerta OK
            <AlertaAccion 
                :tipo="true"
                mensaje="<strong>Certificado cargado con éxito, ahora puedes ceder tus facturas sin problemas.</strong>"
                v-on:accionClick="hiddenDiv = false"
            />
        Alerta Error
            <AlertaAccion 
                :tipo="false"
                :accion="{text:'Cargar certificado'}"
                mensaje="<strong>Para ceder tus facturas debes cargar tu certificado digital del SII (Extensión PFX)</strong>"
                v-on:accionClick="certificado = true"
           />
    */

	@Component({
		props: {
			mensaje : String,
            tipo : Boolean,
            accion : {
                type: Object,
                required: false
            },
            // se utiliza cuando hay una advertencia
            isWarning: {
                type: Boolean,
                default: false,
            },
        },
        methods:{
            emitClick(){
                this.$emit('accionClick');
            }
        },
        computed:{
            typeError(){
                if(this.$props.isWarning){
                    return 'rc-alertaAccion__warning';
                }
                else{
                    return 'rc-alertaAccion__bad';
                }
            }
        }
	})

	export default class MensajeVacio extends Vue {}
</script>

<style lang="scss" scoped>
    .rc-alertaAccion__warning {
        border: 1px solid #FFD541; 
        border-radius: 30px;
        padding-left: 30px;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        .rc-alertaAccion__accion{
            display: flex;
            justify-content: flex-end;
            height: 100%;
            align-items: center;
            .rc-alertaAccion__button{
                display: flex;
                max-width: 240px;
                height: 42px;
                text-transform: none;
            }
        }
        .rc-alertaAccion__text{
            /* height: 42px; */
            height: 100%;
            justify-content: left;
            span{
                display: flex;
                align-items: center;
            }
            i{
                color: #FFD541;
            }
            div{
                color: $rc-prim-g2;
            }
        }
    }

    .rc-alertaAccion__bad {
        border: 1px solid $rc-sec-error2; 
        border-radius: 30px;
        padding-left: 30px;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        .rc-alertaAccion__accion{
            display: flex;
            justify-content: flex-end;
            height: 100%;
            align-items: center;
            .rc-alertaAccion__button{
                display: flex;
                max-width: 240px;
                height: 42px;
                text-transform: none;
            }
        }
        .rc-alertaAccion__text{
            /* height: 42px; */
            height: 100%;
            justify-content: left;
            span{
                display: flex;
                align-items: center;
            }
            i{
                color: $rc-sec-error2;
            }
            div{
                color: $rc-prim-g2;
            }
        }
    }
    .rc-alertaAccion__ok {
        border: 1px solid $rc-sec-hgl1; 
        border-radius: 30px;
        padding-left: 30px;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        .rc-alertaAccion__accion{
            display: flex;
            justify-content: flex-end;
            .rc-alertaAccion__button{
                display: flex;
                max-width: 240px;
                height: 42px;
                text-transform: none;
            }
            i{
                color: $rc-prim-g1 !important;
                font-size: 2rem;
            }
        }
        .rc-alertaAccion__text{
            /* height: 42px; */
            justify-content: space-between;
            span{
                display: flex;
                align-items: center;
            }
            i{
                color: $rc-sec-hgl1;
            }
            div{
                color: $rc-prim-g2;
            }
        }
    }
    // Breakpoints 
	@each $breakpoint in $breakpoints_screen_text{
		@include breakpoint_media_queries($breakpoint){
			@if $breakpoint == "x-large"{
				
			}@else if $breakpoint == "large"{
				
			}@else if $breakpoint == "medium-to-large"{
				
			}@else if $breakpoint == "small-to-medium"{
			
			}@else if $breakpoint == "small"{
                .rc-alertaAccion__bad{
                    padding-left: 0;
                    .rc-alertaAccion__text{
                        justify-content: center !important;
                    }
                    .rc-alertaAccion__accion{
                        justify-content: center !important;
                        margin-top: 10px;
                        margin-bottom: 10px;
                    }
                }
                .rc-alertaAccion__warning{
                    padding-left: 0;
                    .rc-alertaAccion__text{
                        justify-content: center !important;
                    }
                    .rc-alertaAccion__accion{
                        justify-content: center !important;
                        margin-top: 10px;
                        margin-bottom: 10px;
                    }
                }
                .rc-alertaAccion__ok{
                    padding-left: 0;
                    .rc-alertaAccion__text{
                        justify-content: space-between !important;
                    }
                    .rc-alertaAccion__accion{
                        justify-content: center !important;
                    }
                }
            }
		}
	}
</style>
