<template>
  <div class="dashboard-navbar">
    <v-layout
      wrap
      row
      align-start
      justify-start
      style="position:fixed; background-color:#fafafa;z-index:5"
    >
      <v-flex xs12 @click.stop="drawer = !drawer">
        <v-icon style="cursor: pointer">menu</v-icon>
        <span
          class="font-weight-bold title pb-3"
          style="color: rgba(0, 0, 0, 0.54); cursor: pointer"
          >MENU</span
        >
      </v-flex>
    </v-layout>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      :dark="darkMode"
      temporary
      clipped
    >
      <v-list class="pa-2">
        <v-list-tile avatar>
          <!-- <v-list-tile-avatar @click="darkMode = !darkMode">
            <img v-if="user.genero == null || user.genero == 'masculino' || user.genero == 'M'" src="https://www.w3schools.com/howto/img_avatar.png">
            <img v-if="user.genero == 'femenino' || user.genero == 'F'" src="https://www.w3schools.com/howto/img_avatar2.png">
          </v-list-tile-avatar> -->
          <v-list-tile-content>
            <v-list-tile-title class="font-weight-bold"
              >{{ user.nombre }} {{ user.app_pat }}</v-list-tile-title
            >
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
      <v-list class="transparent pa-2">
        <v-list-tile
          :active-class="activeClass"
          :to="{ name: 'dashboard-stats' }"
          class="v-list-item"
        >
          <v-list-tile-action style="padding-right: 1rem">
            <i class="v-icon material-icons">assignment</i>
          </v-list-tile-action>
          <v-list-tile-title>Mi panel</v-list-tile-title>
        </v-list-tile>
        <v-list class="transparent">
          <v-list-group
            :key="`${menu.icon}-${index}`"
            v-for="(menu, index) in Menus"
            :prepend-icon="menu.icon"
          >
            <template v-slot:activator>
              <v-list-tile :active-class="activeClass" class="v-list-item">
                <v-list-tile-title>{{ menu.text }}</v-list-tile-title>
              </v-list-tile>
            </template>
            <div
              :active-class="activeClass"
              :to="category.to"
              :key="`${category.icon}-${index}`"
              v-for="(category, index) in menu.categories"
              class="v-list-item modified-text"
            >
              <v-list class="transparent">
                <v-list-group
                  v-if="category.links != undefined"
                  class="transparent"
                >
                  <template v-slot:activator>
                    <v-list-tile class="v-list-item">
                      <v-list-tile-action>
                        <i class="material-icons fsize">{{ category.icon }}</i>
                      </v-list-tile-action>
                      <v-list-tile-title>{{ category.text }}</v-list-tile-title>
                    </v-list-tile>
                  </template>
                  <v-list-tile
                    :key="link.icon"
                    v-for="link in category.links"
                    :to="link.to"
                    class="v-list-item"
                  >
                    <v-list-tile-action>
                      <i class="material-icons fsize">{{ link.icon }}</i>
                    </v-list-tile-action>
                    <v-list-tile-title>{{ link.text }}</v-list-tile-title>
                  </v-list-tile>
                </v-list-group>
                <v-list-tile v-else :to="category.to" class="transparent">
                  <v-list-tile-action>
                    <i class="material-icons fsize">{{ category.icon }}</i>
                  </v-list-tile-action>
                  <v-list-tile-title>{{ category.text }}</v-list-tile-title>
                </v-list-tile>
              </v-list>
            </div>
          </v-list-group>
        </v-list>
        <v-list-tile
          :active-class="activeClass"
          :to="MyBusiness.to"
          class="v-list-item"
        >
          <v-list-tile-action>
            <i
              class="v-icon material-icons fsize"
              style="padding-right: 1rem"
              >{{ MyBusiness.icon }}</i
            >
          </v-list-tile-action>
          <v-list-tile-title>{{ MyBusiness.text }}</v-list-tile-title>
        </v-list-tile>
        <v-list-group :prepend-icon="Profile.icon">
          <template v-slot:activator>
            <v-list-tile class="v-list-item" :active-class="activeClass">
              <v-list-tile-content>
                <v-list-tile-title>{{ Profile.text }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </template>
          <v-list-tile
            :active-class="activeClass"
            :to="link.to"
            :key="link.icon"
            v-for="link in Profile.links"
            class=" v-list-item modified-text"
          >
            <v-list-tile-action>
              <i class="material-icons fsize">{{ link.icon }}</i>
            </v-list-tile-action>
            <v-list-tile-title>{{ link.text }}</v-list-tile-title>
          </v-list-tile>
        </v-list-group>
        <v-list-group :prepend-icon="MyDocuments.icon">
          <template v-slot:activator>
            <v-list-tile class="v-list-item fsize" :active-class="activeClass">
              <v-list-tile-content>
                <v-list-tile-title>{{ MyDocuments.text }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </template>
          <v-list-tile
            :active-class="activeClass"
            :to="link.to"
            :key="`${link.icon}-${index}`"
            v-for="(link, index) in MyDocuments.links"
            class=" v-list-item modified-text"
          >
            <v-list-tile-action>
              <i class="material-icons fsize">{{ link.icon }}</i>
            </v-list-tile-action>
            <v-list-tile-title>{{ link.text }}</v-list-tile-title>
          </v-list-tile>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import API from "@/api";
import {
  MyDocuments,
  Profile,
  NewMenus,
  Menus,
  MyBusiness
} from "@/models/DashboardLinks";
import { EventBus } from "@/main";

@Component({
  computed: {
    ...mapState(["user"]),
    activeClass() {
      return "primary white--text";
    },
    accountType() {
      return this.$store.state.user.user_tipo_id == 2
        ? "investor"
        : "applicant";
    }
  },
  data() {
    return {
      title: "Dashboard",
      darkMode: true,
      MyBusiness,
      Menus,
      Profile,
      MyDocuments,
      mini: true,
      right: null,
      canShowMenu: false,
      drawer: false,
      responsive: false,
      tooltip: false
    };
  },
  watch: {
    $route(val) {
      // @ts-ignore
      this.changeTitle();
    }
  },
  async beforeMount() {
    // @ts-ignore
    this.changeTitle();
  },
  mounted() {
    // @ts-ignore
    this.onResponsive();
    // @ts-ignore
    window.addEventListener("resize", this.onResponsive);
  },
  beforeDestroy() {
    // @ts-ignore
    window.removeEventListener("resize", this.onResponsive);
  },
  methods: {
    onResponsive() {
      if (window.innerWidth < 960) {
        this.$data.responsive = true;
      } else {
        this.$data.responsive = false;
      }
    },
    changeTitle() {
      this.$data.title = this.$route.name;
    },
    logout() {
      EventBus.$emit("logout");
    }
  }
})
export default class NavBar extends Vue {}
</script>

<style lang="scss">
.f-size {
  max-width: 24px !important;
  max-height: 24px !important;
  padding: 0 1rem !important;
}
.dashboard-navbar {
  .v-list {
    padding: 5px 0;
  }
  .v-list-item {
    padding: 5px 0;
    &.modified-text {
      margin-left: 5px;
    }
    .v-list__tile {
      border-radius: 5px !important;
    }
  }
}
.v-list__tile.v-list__tile--link.theme--light {
  color: rgba(0, 0, 0, 0.87);
  & .v-list__tile__title {
    color: rgba(0, 0, 0, 0.87);
  }
}
</style>
