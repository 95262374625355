

























































import { Component, Prop, Vue } from "vue-property-decorator";
import Axios from "axios";
import { mapState } from "vuex";
import API from "@/api";
import { EventBus } from "@/main";

@Component({
  data() {
    return {
      dialog: false,
      tabs: null,
      bottomNav: "recent",
      index: 0,
      information: [
        {
          question: "Factura",
          answer:
            "Crédito a corto plazo (máximo 120 días) donde la empresa cede en dominio a RedCapital facturas por cobrar como respaldo."
        },
        {
          question: "Capital de trabajo",
          answer:
            "Crédito de deuda directa destinado a las necesidades de capital de trabajo de la empresa solicitante."
        },
        {
          question: "Garantía hipotecaria",
          answer:
            "Crédito en donde el solicitante ofrece como garantía un bien inmueble, el cual es gravado con una hipoteca."
        },
        {
          question: "Subsidio Estatal",
          answer:
            "Crédito respaldado por un Bono de Riego o Subsidio Serviu. Los pagadores de estos créditos son la Tesorería General de la República o el Serviu respectivamente."
        },
        {
          question: "Monto",
          answer: "Monto solicitado por la empresa solicitante."
        },
        {
          question: "Plazo",
          answer: "Tiempo estipulado de pago del crédito."
        },
        {
          question: "TIR",
          answer:
            "Indica la rentabilidad exigida a una inversión incluyendo todos los costos asociados al crédito."
        },
        {
          question: "Solicitante",
          answer: "Empresa que solicita un crédito en la plataforma."
        },
        {
          question: "Mayor pagador",
          answer:
            "Es la empresa pagadora de la factura con mayor monto a pagar de la oportunidad de inversión y el porcentaje que representa."
        },
        {
          question: "Créditos / Doc. pagados",
          answer:
            "Es el número de créditos o documentos pagados en RedCapital por el solicitante y pagador respectivamente. Los documentos pueden ser facturas, bonos estatales, cheques, letras, etc."
        },
        {
          question: "% pagado en plazo",
          answer: "Representa la calidad de pago del solicitante y pagador."
        }
      ]
    };
  },
  beforeMount() {
    EventBus.$on("open-info-dialog", () => {
      this.$data.dialog = true;
    });
  },
  methods: {
    show(index: number) {
      // index
    }
  }
})
export default class ProjectInfo extends Vue {}
