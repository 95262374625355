


































































import { Component, Prop, Vue } from "vue-property-decorator";
import DniField from "@/components/DniField.vue";
import { mapState } from "vuex";
import API from "@/api";
import { EventBus } from "@/main";
import { Configuration } from "../configuration";
import { Uri } from "@/utils";

@Component({
  computed: mapState(["user"]),
  data() {
    return {
      form: {
        dni: "",
        password: ""
      },
      canPress: true,
      isWaitingForLogin: false,
      dialog: false,
      loggedinSuccesfully: false,
      showPassword: false,
      redirect: null,
      error: null,
      isButtonClickable: false,
      rules: {
        password: [(v: any) => !!v || "La contraseña es requerida"]
      }
    };
  },
  watch: {
    form: {
      handler(val) {
        // @ts-ignore
        if (this.$refs.login.validate()) {
          this.$data.isButtonClickable = true;
        } else {
          this.$data.isButtonClickable = false;
        }
      },
      deep: true
    }
  },
  beforeMount() {
    this.$data.canPress = true;
    EventBus.$on("open-login-dialog", (path: string) => {
      if (path != undefined) {
        this.$data.redirect = Uri.string(path);
      }
      this.$data.dialog = true;
      this.$data.error = null;
    });
  },
  methods: {
    async onSubmit() {
      this.$data.canPress = false;
      this.$data.isButtonClickable = true;
      this.$data.error = null;

      API.login(this.$data.form.dni, this.$data.form.password)
        .then((data: any) => {
          this.$store.commit("setMenu", null);
          this.$store.commit("setUser", data.usuario);
          this.$store.commit("setToken", data.access_token);
          this.$store.commit("setExpireTime", data.expires_at);
				  this.$store.commit("setMenu", data.usuario.user_tipo_id);

          this.$data.dialog = false;
          setTimeout(() => {
            this.$data.loggedinSuccesfully = true;
            setTimeout(() => {
              this.$data.loggedinSuccesfully = false;
            }, Configuration.Redirection.timeout);
          }, 500);
          EventBus.$emit("login-successfully");
          setTimeout(() => {
            if (this.$data.redirect != null) {
              if (this.$data.redirect.indexOf("/") == 0) {
                this.$router.push({
                  path: `${this.$data.redirect}`
                });
              } else {
                this.$router.push({
                  path: `/${this.$data.redirect}`
                });
              }
            } else {
              this.$router.push({
                path: "/panel/estadisticas"
              });
            }
          }, 500);
          this.$data.canPress = true;
        })
        .catch(error => {
          this.$data.canPress = true;
          this.$data.error = "Usuario y/o contraseña incorrecta";
          EventBus.$emit("snack-error", this.$data.error);
        })
        .finally(() => {
          this.$data.isButtonClickable = true;
        });
    },
    dialogWasCancel() {
      this.$data.dialog = false;
      this.$data.canPress = true;
      EventBus.$emit("login-dialog-cancelled");
    }
  },
  components: {
    DniField
  }
})
export default class Login extends Vue {}
