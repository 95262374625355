import Stats from '@/views/dashboard/Stats.vue'
import PersonalData from '@/views/dashboard/misDatos.vue'

export default [
  {
    path: 'estadisticas',
    name: 'stats',
    component: Stats,
  },
  {
    path: 'datos-personales',
    name: 'personal-data',
    component: PersonalData,
  },
  {
    path: 'cambiar-contraseña',
    name: 'change-password',
    component: () => import( /* webpackChunkName: "change-password" */ '@/views/dashboard/ChangePassword.vue'),
  },
  {
    path: 'mis-empresas',
    name: 'my-business',
    component: () => import( /* webpackChunkName: "business" */ '@/views/dashboard/Business.vue'),
  },
  {
    path: 'empresas',
    name: 'business',
    component: () => import( /* webpackChunkName: "business" */ '@/views/dashboard/Business.vue'),
  },
  {
    path: 'crear-empresa',
    name: 'create-business',
    component: () => import( /* webpackChunkName: "create-business" */ '@/views/dashboard/CreateBusiness.vue'),
  },
  {
    path: 'editar-empresa/:companyId',
    name: 'edit-business',
    component: () => import( /* webpackChunkName: "edit-business" */ '@/views/dashboard/EditBusiness.vue'),
    props: true,
  },
  {
    path: 'solicitar/factoring',
    name: 'request-factoring',
    component: () => import(/* webpackChunkName: "factoring" */ '@/views/dashboard/Factoring.vue'),
  },
  {
    path: 'solicitar/credito',
    name: 'request-credit',
    component: () => import(/* webpackChunkName: "credit" */ '@/views/dashboard/Credit.vue'),
  },
  {
    path: 'contratos',
    name: 'contracts',
    component: () => import(/* webpackChunkName: "contracts" */ '@/views/dashboard/Contracts.vue'),
  },
  {
    path: 'mandatos',
    name: 'terms',
    component: () => import(/* webpackChunkName: "terms" */ '@/views/dashboard/Terms.vue'),
  },
  {
    path: 'declaraciones',
    name: 'declaration',
    component: () => import(/* webpackChunkName: "terms" */ '@/views/dashboard/Declarations.vue'),
  },
  {
    path: 'pagares-y-certificados',
    name: 'iou-and-certificates',
    component: () => import(/* webpackChunkName: "iou-and-certificates" */ '@/views/dashboard/IOUAndCertificates.vue'),
  },
]